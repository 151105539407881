import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { withStyles } from '@material-ui/core/styles';


const styles = {
    card2: {
        margin:"5px", 
        width: "220px",
        backgroundColor: '#E4E4E4',
        height: "710px",
        textAlign: "center",
        cursor: 'pointer'
      },

    title2: {
        marginTop: '30px',
        color: '#535353',
        fontSize: '14px'
      },
      addIcon: {
          marginTop: '250px',
          color: '#878787',
          width: 50,
          height: 50,
      },

  };


  class AddBox extends Component {

    render() {
            const { classes } = this.props;
            return (
                <div>
                    <Card className={classes.card2}>      
                        <CardContent className={classes.gridStyle} onClick={this.props.onClick}>
                            <AddCircleIcon className={classes.addIcon}/>
                            <p className={classes.title2}>Add an offer</p>
                        </CardContent>
                    </Card>
                </div>    
                            
            );
        }
    }


export default withStyles(styles)(AddBox);
    


