import { normalize } from "normalizr";
import { Dispatch } from "redux";
import applicationsService from "../services/applications";
import { applicationSchema } from "../schemas/schemas";
import { DraftNote, Note } from "../types/Note";
import { DraftOffer, Offer } from "../types/Offer";

export const FETCH_APPLICATIONS_REQUEST = "FETCH_APPLICATIONS_REQUEST";
export const FETCH_APPLICATIONS_SUCCESS = "FETCH_APPLICATIONS_SUCCESS";
export const FETCH_APPLICATIONS_FAILURE = "FETCH_APPLICATIONS_FAILURE";

const fetchApplicationDataRequest = () => ({
  type: FETCH_APPLICATIONS_REQUEST
});

// const fetchApplicationDataFailure = ( payload ) => ({
//     type: FETCH_APPLICATIONS_FAILURE,
//     payload
// });

const fetchApplicationDataSuccess = (payload: any) => ({
  type: FETCH_APPLICATIONS_SUCCESS,
  payload
});

export const fetchApplicationData = () => (dispatch: Dispatch) => {
  dispatch(fetchApplicationDataRequest());
  applicationsService.fetchApplicationData().then(data => {
    const normalizedData = normalize(data, [applicationSchema]);
    dispatch(fetchApplicationDataSuccess(normalizedData));
  });
};

export const FILTER_APPLICATION_DATA = "FILTER_APPLICATION_DATA";

export const filterApplicationData = (key: any, value: any) => ({
  type: FILTER_APPLICATION_DATA,
  payload: {
    key,
    value
  }
});

export const SET_CURRENT_APPLICATION = "SET_CURRENT_APPLICATION";

export const setCurrentAplication = (payload: any) => ({
  type: SET_CURRENT_APPLICATION,
  payload
});

export const GET_APPLICATION_BY_ID_REQUEST = "GET_APPLICATION_BY_ID_REQUEST";
export const GET_APPLICATION_BY_ID_SUCCESS = "GET_APPLICATION_BY_ID_SUCCESS";
export const GET_APPLICATION_BY_ID_FAILURE = "GET_APPLICATION_BY_ID_FAILURE";

const getApplicationByIdRequest = () => ({
  type: GET_APPLICATION_BY_ID_REQUEST
});

const getApplicationByIdSuccess = (id: number, data: any) => ({
  type: GET_APPLICATION_BY_ID_SUCCESS,
  payload: {
    id,
    data
  }
});

const getApplicationByIdFailure = (error: any) => ({
  type: GET_APPLICATION_BY_ID_FAILURE,
  error
});

export const getApplicationById = (id: number) => async (
  dispatch: Dispatch
) => {
  dispatch(getApplicationByIdRequest());
  try {
    const response = await applicationsService.fetchApplicationById(id);
    dispatch(getApplicationByIdSuccess(id, response));
  } catch (error) {
    dispatch(getApplicationByIdFailure(error));
  }
};

export const CHANGE_APPLICATION_STATUS_REQUEST =
  "CHANGE_APPLICATION_STATUS_REQUEST";
export const CHANGE_APPLICATION_STATUS_SUCCESS =
  "CHANGE_APPLICATION_STATUS_SUCCESS";
export const CHANGE_APPLICATION_STATUS_FAILURE =
  "CHANGE_APPLICATION_STATUS_FAILURE";

export const changeApplicationStatusRequest = () => ({
  type: CHANGE_APPLICATION_STATUS_REQUEST
});

export const changeApplicationStatusFailure = (error: any) => ({
  type: CHANGE_APPLICATION_STATUS_FAILURE,
  error
});

export const changeApplicationStatus = (id: number, status: number) => async (
  dispatch: Dispatch
) => {
  dispatch(changeApplicationStatusRequest());
  try {
    const response = await applicationsService.changeApplicationStatus(
      id,
      status
    );
    dispatch(getApplicationByIdSuccess(id, response));
  } catch (error) {
    dispatch(changeApplicationStatusFailure(error));
  }
};

export const ADD_NOTE_TO_APPLICATION_REQUEST =
  "ADD_NOTE_TO_APPLICATION_REQUEST";
export const ADD_NOTE_TO_APPLICATION_SUCCESS =
  "ADD_NOTE_TO_APPLICATION_SUCCESS";
export const ADD_NOTE_TO_APPLICATION_FAILURE =
  "ADD_NOTE_TO_APPLICATION_FAILURE";

export const addNoteToApplicationFailure = (error: any) => ({
  type: "ADD_NOTE_TO_APPLICATION_FAILURE",
  error
});

export const addNoteToApplicationSuccess = (id: number, data: any) => ({
  type: "ADD_NOTE_TO_APPLICATION_SUCCESS",
  payload: {
    id,
    data
  }
});

export const addNoteToApplication = (id: number, note: DraftNote) => async (
  dispatch: Dispatch
) => {
  try {
    const response: Array<
      Note
    > = await applicationsService.addNoteToApplication(id, note);
    dispatch(addNoteToApplicationSuccess(id, response));
    dispatch(toggleNewApplicationModal());
  } catch (error) {
    dispatch(addNoteToApplicationFailure(error));
    dispatch(toggleNewApplicationModal());
  }
};

export const TOGGLE_NEW_APPLICATION_MODAL = "TOGGLE_NEW_APPLICATION_MODAL";
export const toggleNewApplicationModal = () => ({
  type: TOGGLE_NEW_APPLICATION_MODAL
});

export const ADD_OFFER_TO_APPLICATION_REQUEST =
  "ADD_OFFER_TO_APPLICATION_REQUEST";
export const ADD_OFFER_TO_APPLICATION_SUCCESS =
  "ADD_OFFER_TO_APPLICATION_SUCCESS";
export const ADD_OFFER_TO_APPLICATION_FAILURE =
  "ADD_OFFER_TO_APPLICATION_FAILURE";

export const addOfferToApplicationFailure = (error: any) => ({
  type: "ADD_OFFER_TO_APPLICATION_FAILURE",
  error
});

export const addOfferToApplicationSuccess = (id: number, data: any) => ({
  type: "ADD_OFFER_TO_APPLICATION_SUCCESS",
  payload: {
    id,
    data
  }
});

export const addOfferToApplication = (id: number, offer: DraftOffer) => async (
  dispatch: Dispatch
) => {
  try {
    const response: Array<
      Offer
    > = await applicationsService.addOfferToApplication(id, offer);
    dispatch(addOfferToApplicationSuccess(id, response));
    return true;
  } catch (error) {
    dispatch(addOfferToApplicationFailure(error));
    return false;
  }
};

export const DELETE_OFFER_FROM_APPLICATION_REQUEST =
  "DELETE_OFFER_FROM_APPLICATION_REQUEST";
export const DELETE_OFFER_FROM_APPLICATION_SUCCESS =
  "DELETE_OFFER_FROM_APPLICATION_SUCCESS";
export const DELETE_OFFER_FROM_APPLICATION_FAILURE =
  "DELETE_OFFER_FROM_APPLICATION_FAILURE";

export const deleteOfferFromApplicationFailure = (error: any) => ({
  type: "DELETE_OFFER_FROM_APPLICATION_FAILURE",
  error
});

export const deleteOfferFromApplicationSuccess = (id: number, data: any) => ({
  type: "DELETE_OFFER_FROM_APPLICATION_SUCCESS",
  payload: {
    id,
    data
  }
});

export const deleteOfferFromApplication = (
  id: number,
  offer: DraftOffer
) => async (dispatch: Dispatch) => {
  try {
    const response: Array<
      Offer
    > = await applicationsService.deleteOfferFromApplication(id, offer);
    dispatch(deleteOfferFromApplicationSuccess(id, response));
  } catch (error) {
    dispatch(deleteOfferFromApplicationFailure(error));
  }
};

export const EDIT_OFFER_FROM_APPLICATION_REQUEST =
  "EDIT_OFFER_FROM_APPLICATION_REQUEST";
export const EDIT_OFFER_FROM_APPLICATION_SUCCESS =
  "EDIT_OFFER_FROM_APPLICATION_SUCCESS";
export const EDIT_OFFER_FROM_APPLICATION_FAILURE =
  "EDIT_OFFER_FROM_APPLICATION_FAILURE";

export const editOfferFromApplicationFailure = (error: any) => ({
  type: "EDIT_OFFER_FROM_APPLICATION_FAILURE",
  error
});

export const editOfferFromApplicationSuccess = (id: number, data: any) => ({
  type: "EDIT_OFFER_FROM_APPLICATION_SUCCESS",
  payload: {
    id,
    data
  }
});

export const editOfferFromApplication = (
  id: number,
  offerId: number,
  offer: DraftOffer
) => async (dispatch: Dispatch) => {
  try {
    const response: Array<
      Offer
    > = await applicationsService.editOfferFromApplication(id, offerId, offer);
    dispatch(editOfferFromApplicationSuccess(id, response));
  } catch (error) {
    dispatch(editOfferFromApplicationFailure(error));
  }
};

export const SEND_OFFERS_TO_CUSTOMER_REQUEST =
  "SEND_OFFERS_TO_CUSTOMER_REQUEST";
export const SEND_OFFERS_TO_CUSTOMER_SUCCESS =
  "SEND_OFFERS_TO_CUSTOMER_SUCCESS";
export const SEND_OFFERS_TO_CUSTOMER_FAILURE =
  "SEND_OFFERS_TO_CUSTOMER_FAILURE";

export const sendOffersToCustomerFailure = (error: any) => ({
  type: "SEND_OFFERS_TO_CUSTOMER_FAILURE",
  error
});

export const sendOffersToCustomerSuccess = (id: number, data: any) => ({
  type: "SEND_OFFERS_TO_CUSTOMER_SUCCESS",
  payload: {
    id,
    data
  }
});

export const sendOffersToCustomer = (id: number) => async (
  dispatch: Dispatch
) => {
  try {
    const response: Array<
      Offer
    > = await applicationsService.sendOffersToCustomer(id);
    dispatch(sendOffersToCustomerSuccess(id, response));
  } catch (error) {
    dispatch(sendOffersToCustomerFailure(error));
  }
};
