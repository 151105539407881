import * as actions from "actions/leads";
import { fromJS } from "immutable";

const initialState = fromJS({
  ui: {
    loading: false,
    nameFilter: ""
  },
  entities: {
    leads: {}
  }
});

const leads = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_LEADS_REQUEST:
      return state.setIn(["ui", "loading"], true);
    case actions.FETCH_LEADS_SUCCESS: {
      const { leads } = action.payload;
      return state
        .setIn(["ui", "loading"], false)
        .setIn(["entities", "leads"], fromJS(leads));
    }
    // TODO changen to immutability scheme
    // case actions.FILTER_LEADS:
    //   return {
    //     ...state,
    //     ui: {
    //       ...state.ui,
    //       [`${action.payload.key}Filter`]: action.payload.value
    //     }
    //   };
    default:
      return state;
  }
};

export default leads;
