import React, {Component} from 'react';
import PersonalInfo from './PersonalInfo';
import BusinessInfo from './BusinessInfo';
import BusinessFinancial from './BusinessFinancial';
import Grid from '@material-ui/core/Grid';
import { fetchCustomerDetails } from './../../actions/customerDetails';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import toJS from "../../components/hoc/toJS";

const styles = {}

class CustomerDetails extends Component {
	componentDidMount(){
		const { getCustomerDetails } = this.props;
		getCustomerDetails();
	}
	
    render() {
		const { customerDetails, token } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={16}>
                    <Grid item xs>
                        <PersonalInfo customerDetails={customerDetails}/>
                    </Grid>
                    <Grid item xs>
                        <BusinessInfo customerDetails={customerDetails} />
                    </Grid>
                    <Grid item xs>
                        <BusinessFinancial customerDetails={customerDetails} token={token}/>
                    </Grid>
                </Grid>
	        </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
  token: state.getIn(["auth", "user", "token"]),
  customerDetails: state.getIn(["customerDetails", "customerDetails"]),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { id }
    }
  } = ownProps;
  return {
    getCustomerDetails: () => dispatch(fetchCustomerDetails(id)),
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(toJS(CustomerDetails)));
