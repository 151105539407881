import React, { PureComponent, ChangeEvent } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { states } from "../../helpers/selectData";
import TableFilter from "../../components/Tables/TableFilter";
import TableEmptyRows from "../../components/Tables/TableEmptyRows";
import { formatMoney } from "../../utils/format";
import {
  fetchApplicationData,
  filterApplicationData
} from "../../actions/applications";
import config from "../../config/index";
import { push } from "connected-react-router";
import toJS from "../../components/hoc/toJS";
import styles from "../../utils/sharedTableStyles";

import { selectFilteredApplications } from "../../selectors/applications";

import { fetchStatuses } from "../../actions/app";

interface ComponentProps {
  data: Array<any>;
  dispatch: Dispatch<any>;
  token: string;
  loading: boolean;
  classes: any;
  status_codes: string[];
  stateFilter: string;
  bizNameFilter: string;
  statusFilter: string;
}

class Applications extends PureComponent<ComponentProps> {
  state = {
    page: 0,
    rowsPerPage: 10
  };

  handleChangePage = (_: any, page: number) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchStatuses());
    dispatch(fetchApplicationData());
  }

  goToApplication = (id: number) => {
    const { dispatch } = this.props;
    dispatch(push(`/admin/application/${id}`));
  };

  downloadApplication = (applicationId: number) => {
    const { token } = this.props;
    window.location.assign(
      `${config.apiUrl}/report-loanapp?id=${applicationId}&token=${token}`
    );
  };

  handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { dispatch } = this.props;
    const {
      target: { name, value }
    } = event;
    dispatch(filterApplicationData(name, value));
  };

  renderUTMTags = (utmTags: any) => {
    return utmTags ? (
      <React.Fragment>
        {utmTags.utm_source && (
          <div>
            <b>Source:</b> {utmTags.utm_source}
          </div>
        )}
        {utmTags.utm_campaign && (
          <div>
            <b>Campaign:</b> {utmTags.utm_campaign}
          </div>
        )}
        {utmTags.utm_medium && (
          <div>
            <b>Medium:</b> {utmTags.utm_medium}
          </div>
        )}
        {utmTags.utm_content && (
          <div>
            <b>Content:</b> {utmTags.utm_content}
          </div>
        )}
        {utmTags.utm_term && (
          <div>
            <b>Term:</b> {utmTags.utm_term}
          </div>
        )}
      </React.Fragment>
    ) : null;
  };

  render() {
    const {
      classes,
      data,
      loading,
      status_codes,
      stateFilter,
      bizNameFilter,
      statusFilter
    } = this.props;
    const { rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={classes.root}>
        <h2 className={classes.titleStyle}>Applications</h2>
        <div className={classes.toolbar}>
          <TableFilter
            onChange={this.handleFilterChange}
            options={status_codes}
            filter="status"
            label="Status"
            value={statusFilter}
          />
          <TableFilter
            onChange={this.handleFilterChange}
            label="Business Name"
            filter="bizName"
            type="input"
            value={bizNameFilter}
          />
          <TableFilter
            onChange={this.handleFilterChange}
            options={states}
            filter="state"
            valueKey="code"
            label="State"
            value={stateFilter}
          />
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.head}>
              <TableCell
                className={`${classes.headStyle} ${classes.dateCell}`}
                padding="checkbox"
              >
                Date of app
              </TableCell>
              <TableCell
                className={`${classes.headStyle} ${classes.dateCell}`}
                padding="none"
              >
                Last update
              </TableCell>
              <TableCell
                className={`${classes.headStyle} ${classes.statusCell}`}
                padding="none"
              >
                Status
              </TableCell>
              <TableCell
                className={`${classes.headStyle} ${classes.revenueCell}`}
                padding="checkbox"
              >
                Monthly Revenue
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Business name
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                State
              </TableCell>
              <TableCell className={classes.headStyle} padding="none">
                Contact name
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Industry type
              </TableCell>
              <TableCell className={classes.headStyle} padding="none">
                Application PDF
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                UTMTags
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  className={classes.selectable}
                  onClick={() => this.goToApplication(row.id_loan_app)}
                >
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {moment(row.date_of_app).format("MMM DD YYYY")}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {moment(row.last_update).format("MMM DD YYYY")}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {row.statusName}
                  </TableCell>
                  <TableCell
                    className={classes.cellStyle}
                    padding="checkbox"
                    align="right"
                  >
                    {formatMoney(row.monthly_revenue)}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.business_name}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.state}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {row.contac_name}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.industry_type}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {row.id_loan_app ? (
                      <span
                        className={classes.link}
                        onClick={e => {
                          e.stopPropagation();
                          this.downloadApplication(row.id_loan_app);
                        }}
                      >
                        Application PDF
                      </span>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.utmTags
                      ? this.renderUTMTags(row.utmTags)
                      : "Not available"}
                  </TableCell>
                </TableRow>
              ))}
            <TableEmptyRows
              emptyRows={emptyRows}
              loading={loading}
              dataLength={data.length}
              colSpan={10}
            />
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = (state: any) => ({
  data: selectFilteredApplications(state),
  statusFilter: state.getIn(["applications", "ui", "statusFilter"]),
  bizNameFilter: state.getIn(["applications", "ui", "bizNameFilter"]),
  stateFilter: state.getIn(["applications", "ui", "stateFilter"]),
  loading: state.getIn(["applications", "ui", "loading"]),
  status_codes: state.getIn(["app", "status_codes"]),
  states: state.getIn(["app", "states"]),
  token: state.getIn(["auth", "user", "token"])
});

export default connect(mapStateToProps)(withStyles(styles)(toJS(Applications)));
