import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Https';
import get from "lodash.get";
import moment from 'moment';

const styles = {
    card: {
      margin:"10px 0", 
      /*width: "300px",*/
      color: "#7F7F7F", 
      height: '560px'
    },
    title: {
      color: '#535353'
    },

    title2: {
        marginTop: '30px',
        color: '#535353',
        fontSize: '14px',
        fontWeight: 'bold'
      },
    pos: {
      marginBottom: '20px',
      color: '#878787',
      fontSize: '14px',
      lineHeight: '22px'

    },
    spaceTitle: {
        display: 'flex',
        justifyContent: 'initial',
        color: '#535353'
    },
    icon: {
        width: 15,
        height: 15,
    },
    iconTitle: {
        margin: "11px 15px 0 0",
        cursor: "pointer",
    }

  };


class PersonalInfo extends Component {

    render() {
        const { classes, customerDetails } = this.props;
		const customer = get(customerDetails,  "customer");
		const application = get(customerDetails,  "applications.0");
		const owner = get(application, "business.owners.0");
		if(!application){
			return null;
		}				
        return (
            <React.Fragment>

                <div className={classes.space2}>
                    <div>
                    
                        <Card className={classes.card}>
                            <CardContent className={classes.gridStyle}>

                                <div className={classes.spaceTitle}>
                                    <PersonIcon className={classes.iconTitle}/>
                                    <span>
                                            <p>Personal info</p>
                                    </span>
                                </div>

                                <p className={classes.title2}>
                                    { get(owner, "firstName") } { get(owner, "lastName") }
                                </p>
                                <p className={classes.pos} >
                                    { get(owner, "address") } <br />
									{ get(owner, "city") } { get(owner, "ziCode") } <br />
                                    { get(owner, "phoneNumber") } <br />
                                    { get(customer, "email") }
                                </p>
                                <p className={classes.pos} >
                                    Day of birthday <br />
                                    { get(owner, "birthday") ? moment(get(owner, "birthday")).format("DD/MM/YYYY") : "Unknown" }
                                </p>
                                <p className={classes.pos} >
                                <LockIcon className={classes.icon}/> Social Security Number <br />
                                    { get(owner, "socialSecurityNumber" ) }
                                </p>
                      
                                <p className={classes.pos} >
                                    Ownership <br/>
                                    { get(owner, "percentageOwned" ) }%
                                </p>    
                            </CardContent>
                        </Card>
                    </div>

                </div>
                        
        </React.Fragment>
        );
    }
}

PersonalInfo.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(PersonalInfo);