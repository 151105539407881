import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatMoney } from "utils/format";

const styles = {
	card: {
		margin:"5px", 
		width: "220px",
		color: '#7F7F7F',
		//height: "710px",
		textAlign: "center"
	},
	button: {
		fontSize: '12px',
		color: 'white',
		backgroundColor: '#FF8700',
		textTransform: 'none',
		marginTop: "40px"
	},
	title: {
		color: 'white',
		textAlign: "left",
		marginLeft: '10px'
	},
	title2: {
		color: '#00C1AE',
		textAlign: "center",
		margin: "10px 0 5px 0"
	},
	title3: {
	  color: '#535353',
	  textAlign: "center",
	  margin: "8px 0 4px 0"
	},
	pos: {
		color: '#00C1AE',
		fontSize: '24px',
		margin: '5px 0 10px 0'
	},
	pos2: {
		color: '#535353',
		fontSize: '18px',
		margin: '4px 0 8px 0'
	},
	icon: {
		color: 'white',
		cursor: 'pointer',
		marginTop: '15px',
		width: 20,
		height: 20,
	},
	iconDelete: {
		color: 'white',
		cursor: 'pointer',
		margin: '18px 10px 0 10px',        
		width: 20,
		height: 20,
	},
	space: {
		display: 'flex',
		justifyContent: 'space-between',
		background: '#00C1AE',
		marginTop: '-16px',
		marginLeft: '-25px',
		marginRight: '-25px',
		padding: '10px'
	},
	funding: {
		background: '#E5F9F7',
		marginTop: '-10px',
		marginLeft: '-25px',
		marginRight: '-25px',
		padding: '10px'
	}
};


class Offer extends Component {

	onDelete = () => {
		const { offer, onDelete } = this.props;
		onDelete && window.confirm("Are you sure you want to delete this offer?") && onDelete(offer);
	}
	
	onEdit = () => {
		const { offer, onEdit } = this.props;
		onEdit && onEdit(offer);
	}
	
	getPercentageSpecifyValue = () => {
		const { offer } = this.props;
		const currentValues = offer;
		if(Number(currentValues.fundings) && Number(currentValues.factorRate) && Number(currentValues.term) && Number(currentValues.averageMonthly)) {
			let monthlyPayment = ( currentValues.fundings * currentValues.factorRate) / currentValues.term;
			let percentage = Number(monthlyPayment * 100 / currentValues.averageMonthly)
			return (Math.round(percentage*100)/100)+"%";
		} else {
			return "-";
		}
	}

	render() {
      const { classes, offer } = this.props;
      return (
          <React.Fragment>
            <div>
                <Card className={classes.card}>
                    <CardContent className={classes.gridStyle}>
						<div className={classes.space}>
							<span>
							  <h3 className={classes.title}>{offer.term} Months</h3>
							</span>
							<span>
								<EditIcon onClick={this.onEdit} className={classes.icon} />
								<DeleteIcon onClick={this.onDelete} className={classes.iconDelete} />
							</span>
						</div>
						<div className={classes.funding}>
							<h3 className={classes.title2}>
								Funding amount
							</h3>
							<p style={{textAlign: "center"}} className={classes.pos}>
								{formatMoney(offer.fundings)}
							</p>
							<h3 className={classes.title2}>
								Factor rate
							</h3>
							<p style={{textAlign: "center"}} className={classes.pos}>
								{offer.factorRate.toFixed(2)}%
							</p>
						</div>
						{ offer.offerType === "CA" && 
							<h3 className={classes.title3}>
								Percentage Specify
							</h3>
						}
						{ offer.offerType === "CA" && 
							<p style={{textAlign: "center"}} className={classes.pos2}>
								{ this.getPercentageSpecifyValue()}
							</p>
						}
						<h3 className={classes.title3}>
							{ offer.offerType === "CA" && "Estimated "} Total payback
						</h3>
						<p style={{textAlign: "center"}} className={classes.pos2}>
							{formatMoney(offer.totalPayBack)}
						</p>
						<hr />
						<h3 className={classes.title3}>
							{ offer.offerType === "CA" && "Estimated "} Fees
						</h3>
						<p style={{textAlign: "center"}} className={classes.pos2}>
							{formatMoney(offer.fees)}
						</p>
						<hr />
						<h3 className={classes.title3}>
							{ offer.offerType === "CA" && "Estimated "} Weekly Pay ({ offer.weeks })
						</h3>
						<p style={{textAlign: "center"}} className={classes.pos2}>
							{formatMoney(offer.weeklyPay)}
						</p>
						<hr />
						<h3 className={classes.title3}>
							{ offer.offerType === "CA" && "Estimated "} Daily Pay ({ offer.days * offer.term })
						</h3>
						<p style={{textAlign: "center"}} className={classes.pos2}>
							{formatMoney(offer.dailyPay)}
						</p>
                        
                    </CardContent>
                    
                </Card>
            </div>

                      
      </React.Fragment>
      );
  }
}

Offer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Offer);



