import { normalize } from "normalizr";
import leadsService from "../services/leads";
import { leadSchema } from "schemas/schemas";

export const FETCH_LEADS_REQUEST = "FETCH_LEADS_REQUEST";
export const FETCH_LEADS_SUCCESS = "FETCH_LEADS_SUCCESS";
// export const FETCH_LEADS_FAILURE = "FETCH_LEADS_FAILURE";

const fetchLeadsDataRequest = () => ({
  type: FETCH_LEADS_REQUEST
});

// const fetchCustomerDataFailure = (payload) => ({
//     type: FETCH_CUSTOMER_DATA_FAILURE,
//     payload
// });

const fetchLeadsDataSuccess = payload => ({
  type: FETCH_LEADS_SUCCESS,
  payload
});

export const fetchLeadsData = () => dispatch => {
  dispatch(fetchLeadsDataRequest());
  leadsService.fetchLeadsData().then(data => {
    const normalizedData = normalize(data, [leadSchema]);
    dispatch(fetchLeadsDataSuccess(normalizedData.entities));
  });
};

export const FILTER_LEADS = "FILTER_LEADS";

export const filterLeadsData = (key, value) => ({
  type: FILTER_LEADS,
  payload: {
    key,
    value
  }
});
