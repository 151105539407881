import { schema } from "normalizr";

export const customerSchema = new schema.Entity(
  "customers",
  {},
  { idAttribute: "user_id" }
);

export const customerDetailsSchema = new schema.Entity(
  "customerDetails",
  {},
  { idAttribute: "id" }
);

export const applicationSchema = new schema.Entity(
  "applications",
  {
    author: customerSchema
  },
  { idAttribute: "id_loan_app" }
);

export const leadSchema = new schema.Entity("leads", {}, { idAttribute: "id" });

export const loginLogSchema = new schema.Entity("loginLog", {}, { idAttribute: "id" });


