import React from 'react';

import publicRoutes from "routes/public";
import { switchRoutes } from "helpers/routes";

class Public extends React.Component {
    render() {
        return switchRoutes(publicRoutes);
    }
}

export default Public;
