import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import { authentication as auth } from "./auth";
import app from "./app";
import applications from "./applications.ts";
import customers from "./customers";
import leads from "./leads";
import loginLog from "./loginLog";
import customerDetails from "./customerDetails";
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as formReducer } from 'redux-form';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    applications,
    auth,
    customers,
    leads,
	loginLog,
	customerDetails,
    loadingBar: loadingBarReducer,
	form: formReducer
  });

export default rootReducer;
