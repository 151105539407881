import api from "../config/api";
import { AxiosResponse } from "axios";

const login = async (username: string, password: string, code: string, secret: string) => {
  const response: AxiosResponse = await api.post("/login", {
    username,
    password,
	code, 
	secret
  });
  const user = response.data;
  // login successful if there's a jwt token in the response
  if (user.token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("user", JSON.stringify(user));
  }
  return user;
};

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

const getAll = async () => {
  const response = await api.get("/users");
  return response.data;
};

const getById = async (id: string | number) => {
  const response: AxiosResponse = await api.get(`/users/${id}`);
  return response.data;
};

const register = async (user: any) => {
  const response: AxiosResponse = await api.post("/users/register", user);
  return response.data;
};

const update = async (user: any) => {
  const response: AxiosResponse = await api.put(`/users/${user.id}`, user);
  return response.data;
};

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = async (id: string | number) => {
  const response: AxiosResponse = await api.delete(`/users/${id}`);
  return response.data;
};

const getUserData = async () => {
  const response: AxiosResponse = await api.get("/me");
  return response.data;
};

const user = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  getUserData
};

export default user;
