import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const TableEmptyRows = ({ emptyRows, loading, dataLength, colSpan }) =>
  emptyRows > 0 ? (
    <React.Fragment>
      {loading && (
        <TableRow>
          <TableCell colSpan={colSpan}>Loading...</TableCell>
        </TableRow>
      )}
      {!loading && !dataLength && (
        <TableRow>
          <TableCell colSpan={colSpan}>
            No applications found matching the given filters
          </TableCell>
        </TableRow>
      )}
      <TableRow style={{ height: 48 * emptyRows - 1 }}>
        <TableCell colSpan={colSpan} />
      </TableRow>
    </React.Fragment>
  ) : null;
export default TableEmptyRows;
