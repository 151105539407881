import Index from '../components/Index/Index';
import Login from "../views/Login/Login";

const publicRoutes = [
    {
        path: "/login",
        component: Login
    },
    {
        path: '/',
        exact: true,
        component: Index
    },
];

export default publicRoutes;
