import { createSelector } from "reselect";

export const customersData = (state: any) =>
  state.getIn(["customers", "entities", "customers"]);

const nameFilter = (state: any) =>
  state.getIn(["customers", "ui", "nameFilter"]);

export const selectFilteredCustomers = createSelector(
  customersData,
  nameFilter,
  (data, name) =>
    Object.values(data.toJS()).filter((element: any) => {
      const firstName = element.firstName ? element.firstName : "";
      const lastName = element.lastName ? element.lastName : "";
      return `${firstName} ${lastName}`.toLowerCase().includes(name.toLowerCase());
    })
);
