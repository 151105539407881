import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const renderTextField = ({ input, type, meta: { touched, error, warning }, ...props }) => <TextField {...input} type={type} {...props} error={touched && error ? true : null} helperText={touched ? error : null}/>
const required = value => value || value===true || value===false ? undefined : 'Required';

const styles = {
    card: {
        margin:"20px auto", 
        width: "500px",
        color: '#7F7F7F',
        height: "350px",
        textAlign: "center"
      },
      button: {
        fontSize: '14px',
        color: 'white',
        backgroundColor: '#FF8700',
        textTransform: 'none',
        marginTop: "40px"
      },
    title: {
      color: '#535353',
      textAlign: "left"
    },
    closeIcon: {
        color: '#FF8700',
        cursor: 'pointer'
    },
    space: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    form: {
        margin:'auto',
        width: "380px"
    }
  };


class Edit extends Component {

  render() {
      const { classes } = this.props;
      return (
          <React.Fragment>
            <div>
                <Card className={classes.card}>
                    <CardContent className={classes.gridStyle}>
                        <div className="card-body">

                            <div className={classes.space}>
                                <span>
                                  <h3 className={classes.title}>Edit info</h3>
                                </span>
                                <span><CloseIcon onClick={this.props.onClick} className={classes.closeIcon} /></span>
                            </div>
                            <div className={classes.form}>
                                <div style={{display:"flex"}}>
                                    <Field
                                        name="fundings"
                                        component={renderTextField}
                                        label="Name"
                                        placeholder="Yulian"
                                        margin="normal"
                                        fullWidth={true}
                                        validate={[required]}
                                        style={{marginRight:"20px"}}
                                    />

                                    <Field
                                        name="rate"
                                        component={renderTextField}
                                        label="Last name"
                                        placeholder="Castaneda"
                                        margin="normal"
                                        fullWidth={true}
                                        validate={[required]}
                                    />
                                </div>

                                <div style={{display:'flex'}}>
                                    <Field
                                        name="term"
                                        component={renderTextField}
                                        label="Phone"
                                        placeholder="964-4877-341"
                                        margin="normal"
                                        fullWidth={true}
                                        validate={[required]}
                                        style={{marginRight:"20px"}}
                                    />

                                    <Field
                                        name="term"
                                        component={renderTextField}
                                        label="Email"
                                        placeholder="yulian@byyuto.com"
                                        margin="normal"
                                        fullWidth={true}
                                        validate={[required]}
                                    />
                                </div>

                                  <Button type="button" variant="contained" color="primary" onClick={this.props.onCreateOffer} className={classes.button}>Save</Button>
                            </div>
                        </div> 
                    </CardContent>
                    
                </Card>
            </div>

                      
      </React.Fragment>
      );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

Edit = reduxForm(
  {
      form: 'Password',
      destroyOnUnmount: false
  })(Edit);


  export default withStyles(styles)(Edit);