import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TravelIcon from '@material-ui/icons/CardTravel';
import config from "config/index.ts";
import get from "lodash.get";

const styles = {
    card: {
      margin:"10px 0", 
      /*width: "320px",*/
      color: "#7F7F7F", 
    },
      buttonCard: {
        width: '80%',
        fontSize: '12px',
        color: '#FF8700',
        borderColor: '#FF8700',
        margin: '5px auto',
        display: 'block',
		textAlign: "center"
      },
      ButtonGroup: {
        marginBottom: '50px'
    },
      viewDocSpace: {
          marginLeft: '20px'
      },
    title: {
      color: '#535353'
    },

    title2: {
        marginTop: '30px',
        color: '#535353',
        fontSize: '14px',
        fontWeight: 'bold'
      },
    content: {
        color: '#535353',
        fontSize: '12px'
    },
    pos: {
      marginBottom: '20px',
      color: '#878787',
      fontSize: '14px'
    },
    space: {
        display: 'flex',
        justifyContent: 'initial',
        color: '#FF8700',
        fontSize: '14px'
    },
    spaceTitle: {
        display: 'flex',
        justifyContent: 'initial',
        color: '#535353'
    },
    icon: {
        marginTop: "8px",
        cursor: "pointer"
    },
    iconTitle: {
        margin: "11px 15px 0 0",
        cursor: "pointer",
    }
  };

class BusinessFinancial extends Component {
	downloadStatement = statement => {
		const { token } = this.props;
		window.location.assign(
		  `${config.apiUrl}/report-statement-file?id=${statement.id}&token=${token}`
		);
	};	
	
    render() {
        const { classes, customerDetails, token } = this.props;
		const application = get(customerDetails,  "applications.0");
		const customer = get(customerDetails,  "customer");
		
		if(!application){
			return null;
		}	
		
        return (
            <React.Fragment>

                <div className={classes.space2}>
                    <div>
                    
                        <Card className={classes.card}>
                            <CardContent className={classes.gridStyle}>
                                <div className={classes.spaceTitle}>
                                    <TravelIcon className={classes.iconTitle}/>
                                    <span>
                                            <p>Business Financials</p>
                                    </span>
                                </div>
                               
                                <h3 className={classes.title2} >
                                    Statements uploaded
                                </h3>

								{ application.statements.map((statement) => 
									<div className={classes.space} key={statement.id}>
										<AssignmentIcon 
											className={classes.icon}
											onClick={this.downloadStatement.bind(this, statement)}
										/>
                                        <p 
											className={classes.viewDocSpace}
											onClick={this.downloadStatement.bind(this, statement)}>
												View doc
										</p>
	                                </div>
								)}                                
                                
								<p className={classes.pos} >
									Do you invoice your business customers? <br/>
									{ application.has_invoices ? "Yes" : "No" }
								</p>
								
								<p className={classes.pos} >
									Outstanding business invoices? <br/>
									{ application.total_outstandings ? "$"+application.total_outstandings : "None" }
								</p>
								
								<p className={classes.pos} >
									Loans <br/>
									{ application.loanCompanyName1 ? `${application.loanCompanyName1} - ${application.loanCompanyBalance1}` : "" }<br/>
									{ application.loanCompanyName2 ? `${application.loanCompanyName2} - ${application.loanCompanyBalance2}` : "" }
								</p> 
                            </CardContent>
                            <div className={classes.ButtonGroup}>
								{ customer.hasQuickbooks &&
									<React.Fragment>
										<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${customer.id}/quickbooks-info/report/aged-receivable-detail?token=${token}`}>QB - Aged Receivable Detail</Button>
										<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${customer.id}/quickbooks-info/report/aged-payable-detail?token=${token}`}>QB - Aged Payable Detail</Button>
										<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${customer.id}/quickbooks-info/report/profit-and-loss?token=${token}`}>QB - Profit and Loss</Button>
										<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${customer.id}/quickbooks-info/report/balance-sheet?token=${token}`}>QB - Balance Sheet</Button>
									</React.Fragment>
								}
                                <Button variant="outlined" size="large" color="primary" className={classes.buttonCard}>See bank connection</Button>
                            </div>
                        </Card>
                    </div>

                </div>
                        
        </React.Fragment>
        );
    }
}

BusinessFinancial.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(BusinessFinancial);