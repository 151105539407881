import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from "axios";
import config from "../config";
import { authHeader } from "../helpers/authHeader";
import { push } from "connected-react-router";
import store from "../store/";
import { userActions } from "../actions/user";

const api: AxiosInstance = axios.create({
  baseURL: config.apiUrl
});

// Add a request interceptor
api.interceptors.request.use(
  (configuration: AxiosRequestConfig): AxiosRequestConfig => {
    // Do something before request is sent
    const auth = authHeader();
    return { ...configuration, headers: { ...configuration.headers, ...auth } };
  },
  (error: AxiosError) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // Do something with response data
    return response;
  },
  (error: AxiosError) => {
    if (error && error.response && error.response.status === 401) {
      store.dispatch(push("/login"));
      store.dispatch(userActions.logout());
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
