import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const switchRoutes = ( routes ) => (
    <React.Fragment>
        {routes.map((prop, key) => {
            if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.to} key={key} />;
            }
            return <Route path={prop.path} exact={prop.exact} component={prop.component} key={key} />;
        })}
    </React.Fragment>
);
