import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

class TableFilter extends Component {
  handleChange = event => {
    const { onChange } = this.props;
    onChange(event);
  };

  render() {
    const {
      classes,
      options,
      label,
      filter,
      type = "select",
      valueKey = "id",
      value
    } = this.props;
    return (
      <form className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor={filter}>{label}</InputLabel>
          {type === "select" && (
            <Select
              value={value}
              onChange={this.handleChange}
              inputProps={{
                name: filter,
                id: filter
              }}
            >
              <MenuItem value="" />
              {options.map((option, index) => (
                <MenuItem value={option[valueKey]} key={index}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          )}
          {type === "input" && (
            <Input
              name={filter}
              id={filter}
              value={value}
              onChange={this.handleChange}
            />
          )}
        </FormControl>
      </form>
    );
  }
}

TableFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  options: PropTypes.array,
  type: PropTypes.string,
  valueKey: PropTypes.string
};

TableFilter.defaultProps = {
  options: []
};

export default withStyles(styles)(TableFilter);
