import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import ApplicationNotes from "../components/Applications/ApplicationNotes/ApplicationNotes";
import NewApplicationNoteModal from "../components/Applications/NewApplicationNoteModal/NewApplicationNoteModal";
import { selectedApplication } from "../selectors/applications";
import {
  addNoteToApplication,
  toggleNewApplicationModal
} from "../actions/applications";
import get from "lodash.get";
import { DraftNote } from "../types/Note";

type Props = {
  addNote(id: number, note: DraftNote): void;
  application: any;
  toggleModal(): void;
  modalIsOpen: boolean;
};

export class ApplicationNotesContainer extends PureComponent<Props> {
  onToggleModal = () => {
    const { toggleModal } = this.props;
    toggleModal();
  };
  onSubmit = (draft: DraftNote) => {
    const { addNote, application } = this.props;
    const id = get(application, "loan_app.id", 0);
    addNote(id, draft);
  };
  render() {
    const { application, modalIsOpen } = this.props;
    return (
      <Fragment>
        <ApplicationNotes
          onAdd={this.onToggleModal}
          notes={get(application, "loan_notes", [])}
        />
        <NewApplicationNoteModal
          open={modalIsOpen}
          onSubmit={this.onSubmit}
          toggle={this.onToggleModal}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: selectedApplication(state),
  modalIsOpen: state.getIn(["applications", "ui", "newNoteModal"])
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    addNote: (id: number, note: DraftNote) =>
      dispatch(addNoteToApplication(id, note)),
    toggleModal: () => dispatch(toggleNewApplicationModal())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationNotesContainer);
