import { createStyles, Theme } from "@material-ui/core/styles";

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      margin: "0 auto",
      marginBottom: spacing.unit * 6,
    },
    submitBtn: {
      textTransform: "none",
      borderRadius: spacing.unit * 3,
      fontWeight: "bold",
      marginTop: `${spacing.unit * 2}px`
    }
  });
