import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssesmetIcon from "@material-ui/icons/Assessment";
import MoneyIcon from "@material-ui/icons/MonetizationOn";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import face from "assets/images/faces/face1.jpg";
import styles from "./styles";
import { selectedApplication } from "selectors/applications.ts";
import { selectStatus } from "selectors/app";
import toJS from "components/hoc/toJS";
import {
  setCurrentAplication,
  getApplicationById,
  changeApplicationStatus
} from "actions/applications.ts";
import isEqual from "lodash.isequal";
import get from "lodash.get";
import {
  formatBusinessYears,
  formatShortDate,
  formatMoney
} from "utils/format";
import { fetchStatuses } from "actions/app";
import config from "config/index.ts";
import ApplicationNotesContainer from "../../../containers/ApplicationNotesContainer.tsx";
import moment from 'moment';

class ApplicationDetails extends Component {
  constructor(props) {
    super(props);
    this.statusSelector = React.createRef();
  }

  componentDidMount() {
    const { selectApplication, getApplication, getStatus } = this.props;
    getStatus();
    selectApplication();
    getApplication();
  }

  componentWillUnmount() {
    const { selectApplication } = this.props;
    selectApplication(0);
  }

  shouldComponentUpdate(prevProps) {
    return (
      !isEqual(prevProps.application, this.props.application) ||
      !isEqual(prevProps.status, this.props.status)
    );
  }

  onChangeStatus = () => {
    const { changeStatus } = this.props;
    const { value } = this.statusSelector.current;
    changeStatus(value);
  };

  handleRoute = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.props.history.push(`/admin/application/${id}/offer`);
  };

  downloadStatement = statement => {
    const { token } = this.props;
    window.location.assign(
      `${config.apiUrl}/report-statement-file?id=${statement.id}&token=${token}`
    );
  };

  downloadApplicationFile = () => {
    const { application, token } = this.props;
    const id = get(application, "loan_app.id", 0);
    window.location.assign(
      `${config.apiUrl}/report-loanapp?id=${id}&token=${token}`
    );
  };

  render() {
    const { classes, application, status, token } = this.props;
	console.log(classes);
    return (
      <React.Fragment>
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <img src={face} alt={"face"} className={classes.image} />
                  </Grid>
                  <Grid item xs={6}>
                    <h3 className={classes.name}>
                      {get(application, "loan_app_short.full_name", "-")}
                    </h3>
                    <p className={classes.content}>{`${get(
                      application,
                      "business_info.legalName",
                      "-"
                    )} ${get(
                      application,
                      "business_info.legalEntityType.name",
                      ""
                    )}`}</p>
                    <p className={classes.content}>
                      {get(application, "loan_app_short.email", "-")}
                    </p>
                    <p className={classes.content}>
                      {get(
                        application,
                        "business_info.preferredContactPhone",
                        "-"
                      )}
                    </p>
                    <p className={classes.content}>
                      Industry:{" "}
                      {get(application, "loan_app_short.industry_type", "-")}
                    </p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={24} style={{ textAlign: "center" }}>
                  <Grid item xs>
                    <QueryBuilderIcon className={classes.icon} />
                    <p className={classes.posNumber}>
                      {application.loan_app_short
                        ? formatBusinessYears(
                            get(application, "business_info.startYear"),
                            get(application, "business_info.startMonth")
                          )
                        : "-"}
                    </p>
                    <p className={classes.pos}>Years in business</p>
                  </Grid>
                  <Grid item xs>
                    <AssesmetIcon className={classes.icon} />
                    <p className={classes.posNumber}>
                      {get(application, "loan_app_short.credit_score", "-")}
                    </p>
                    <p className={classes.pos}>Credit score</p>
                  </Grid>
                  <Grid item xs>
                    <MoneyIcon className={classes.icon} />
                    <p className={classes.posNumber}>
                      {application.loan_app_short
                        ? formatMoney(
                            get(
                              application,
                              "loan_app_short.monthly_revenue",
                              0
                            )
                          )
                        : "-"}
                    </p>
                    <p className={classes.pos}>Avg. monthly revenue</p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <p className={classes.pos}>
                  Actual status:{" "}
                  <span className={classes.title}>
                    {get(application, "loan_app.statusName", "-")}
                  </span>
                </p>
                <select className={classes.select} ref={this.statusSelector}>
                  {status
                    .filter(
                      item =>
                        Number(item.id) !==
                        Number(get(application, "loan_app.status"))
                    )
                    .map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonStatus}
                  onClick={this.onChangeStatus}
                >
                  Change of status
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <h4 className={classes.title}>Application info</h4>
							  <p className={classes.pos}>
								<h4 className={classes.title2}>Date applied:</h4>
								{ application && application.loan_app
								  ? formatShortDate(moment.unix(get(application, "loan_app.createdAt")))
								  : "-"}
							  </p>
							  <p className={classes.pos}>
								<h4 className={classes.title2}>Loan purpose:</h4>
								{application && application.loan_app_short
								  ? get(
									  application,
									  "loan_app_short.loan_purpose",
									  []
									).toString()
								  : "-"}
							  </p>
							  <h4 className={classes.title2}>Funding requested</h4>
								{formatMoney(get(application, "loan_app_short.funding", 0))}
							  
							  { application && application.loan_app &&
								get(application, "loan_app.statements", []) && 
								get(application, "loan_app.statements", []).length > 0 &&
								<React.Fragment>
									<h4 className={classes.title2}>Statements</h4>
									{application && application.loan_app &&
									  get(application, "loan_app.statements", []).map((statement, i) => (
										<div className={classes.spaceDoc} key={statement.id}>
											<p
											  className={classes.viewDocSpace}
											  onClick={this.downloadStatement.bind(this, statement)}
											>
												<AssignmentIcon
												className={classes.iconDoc}
												onClick={this.downloadStatement.bind(this, statement)}
											  />
											  <span className={classes.docName}>{statement.originalFilename}</span>
											</p>
										</div>
									  ))}
								</React.Fragment>
							  }
              </CardContent>
              <div className={classes.ButtonGroup}>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.buttonCard}
                  onClick={this.downloadApplicationFile}
                >
                  Download application pdf
                </Button>
                { application && application.user && application.user.hasQuickbooks &&
					<React.Fragment>
						<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${application.user.id}/quickbooks-info/report/aged-receivable-detail?token=${token}`}>QB - Aged Receivable Detail</Button>
						<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${application.user.id}/quickbooks-info/report/aged-payable-detail?token=${token}`}>QB - Aged Payable Detail</Button>
						<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${application.user.id}/quickbooks-info/report/profit-and-loss?token=${token}`}>QB - Profit and Loss</Button>
						<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${application.user.id}/quickbooks-info/report/balance-sheet?token=${token}`}>QB - Balance Sheet</Button>
					</React.Fragment>
				}
                {/*<Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.buttonCard}
                >
                  See bank connection
                </Button>*/}
              </div>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={this.handleRoute}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              MAKE AN OFFER
            </Button>
          </Grid>
          <Grid item xs={3}>
            <ApplicationNotesContainer />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

ApplicationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired
};

ApplicationDetails.defaultProps = {
  application: {},
  status: []
};

const mapStateToProps = state => ({
  application: selectedApplication(state),
  status: selectStatus(state),
  token: state.getIn(["auth", "user", "token"])
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { id }
    }
  } = ownProps;
  return {
    selectApplication: () => dispatch(setCurrentAplication(id)),
    getApplication: () => dispatch(getApplicationById(id)),
    getStatus: () => dispatch(fetchStatuses()),
    changeStatus: status => dispatch(changeApplicationStatus(id, status))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(toJS(ApplicationDetails)));
