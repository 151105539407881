import React, { MouseEvent, FunctionComponent, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { Note } from "../../../types/Note";
import moment from 'moment';

interface ComponentProps extends WithStyles<typeof styles> {
  onAdd(e: MouseEvent<HTMLElement>): void;
  notes: Array<Note>;
}

const ApplicationNotes: FunctionComponent<ComponentProps> = ({
  classes,
  onAdd,
  notes
}) => (
  <Card className={classes.card}>
    <CardContent>
      <div className={classes.space}>
        <h3 className={classes.title}>Notes</h3>
        <IconButton onClick={onAdd}>
          <AddCircleIcon className={classes.addIcon} />
        </IconButton>
      </div>
      {notes.map(note => (
        <Fragment key={note.id}>
          <div className={classes.space}>
            <span>
              <h3 className={classes.title2}>{note.typeNote}</h3>
            </span>
            <span className={classes.pos}>
              {moment.unix(note.createdAt).format("DD/MM/YYYY")}
            </span>
          </div>
          <p className={classes.noteStatus}>{note.status}</p>
          <p className={classes.title2}>{note.note}</p>
        </Fragment>
      ))}
    </CardContent>
  </Card>
);

export default withStyles(styles)(ApplicationNotes);
