import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import face from "../../assets/images/faces/face1.jpg";
import Password from "./Password";
import Edit from "./Edit";
import { connect } from "react-redux";
// import { Link } from 'react-router-dom';
import toJS from "components/hoc/toJS";

const styles = {
  card: {
    margin: "auto",
    width: "300px",
    color: "#7F7F7F",
    height: "320px",
    textAlign: "center"
  },
  title: {
    color: "#535353"
  },

  title2: {
    marginTop: "30px",
    color: "#535353",
    fontSize: "14px",
    fontWeight: "bold"
  },
  pos: {
    marginBottom: "20px",
    color: "#878787",
    fontSize: "14px",
    lineHeight: "22px"
  },
  spaceTitle: {
    display: "flex",
    justifyContent: "initial",
    color: "#535353"
  },
  icon: {
    color: "white",
    cursor: "pointer",
    marginTop: "8px",
    width: 20,
    height: 20
  },
  iconTitle: {
    margin: "11px 15px 0 0",
    cursor: "pointer"
  },
  center: {
    textAlign: "center"
  },
  space: {
    display: "flex",
    justifyContent: "space-between",
    background: "#FF8700",
    marginTop: "-16px",
    marginLeft: "-25px",
    marginRight: "-25px",
    padding: "20px"
  },
  image: {
    borderRadius: "50%",
    width: "75px",
    marginTop: "-35px"
  },
  link: {
    color: "#FF8700",
    cursor: "pointer"
  }
};

class Profile extends Component {
  state = {
    showProfile: true,
    showEditInfo: false,
    showChangePassword: false
  };

  openEditInfo = () => {
    this.setState({
      showEditInfo: true,
      showProfile: false
    });
  };

  openChangePassword = e => {
    e.preventDefault();
    this.setState({
      showChangePassword: true,
      showProfile: false
    });
  };

  onCloseEdit = () => {
    this.setState({
      showEditInfo: false,
      showProfile: true
    });
  };

  onClosePassword = () => {
    this.setState({
      showChangePassword: false,
      showProfile: true
    });
  };

  render() {
    const { classes, user } = this.props;
    return (
      <React.Fragment>
        {this.state.showProfile ? (
          <Card className={classes.card}>
            <CardContent className={classes.gridStyle}>
              <div className={classes.space}>
                <span />
                <span>
                  <EditIcon
                    onClick={this.openEditInfo}
                    className={classes.icon}
                  />
                </span>
              </div>
              <img src={face} alt={"face"} className={classes.image} />

              <p>
                <span className={classes.title}>
                  {user ? user.username : null}
                </span>{" "}
                <br />
                <span className={classes.title2}>Byyuto LLC</span>
              </p>
              <p className={classes.pos}>
                yulian@byyuto.com <br />
                954 4877 341
              </p>
              <p className={classes.pos}>
                <a
                  href="/admin/edit-password"
                  onClick={this.openChangePassword}
                  className={classes.link}
                >
                  Edit password
                </a>
              </p>
            </CardContent>
          </Card>
        ) : null}

        {this.state.showEditInfo ? <Edit onClick={this.onCloseEdit} /> : null}

        {this.state.showChangePassword ? (
          <Password onClick={this.onClosePassword} />
        ) : null}
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.getIn(["auth", "user", "user"])
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    //selectApplication: () => dispatch(setCurrentAplication(id)),
    //getApplication: () => dispatch(getApplicationById(id)),
    //getStatus: () => dispatch(fetchStatuses()),
    //createOffer: (loanAppId, values) => dispatch(addOfferToApplication(loanAppId, values)),
    //deleteOffer: (loanAppId, offerId) => dispatch(deleteOfferFromApplication(loanAppId, offerId)),
    //editOffer: (loanAppId, offerId, values) => dispatch(editOfferFromApplication(loanAppId, offerId, values)),
    //resetForm: (formName) => dispatch(reset(formName)),
    //initializeForm: (formName, data) => dispatch(initialize(formName, data)),
    //sendOffers: (loanAppId) => dispatch(sendOffersToCustomer(loanAppId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(toJS(Profile)));
