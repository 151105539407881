import { createSelector } from "reselect";
import get from "lodash.get";

const selectApplicationsById = (state: any) =>
  state.getIn(["applications", "entities", "applicationsById"]);

const selectedApplicationId = (state: any) =>
  state.getIn(["applications", "ui", "selected"]);

export const applicationsData = (state: any) =>
  state.getIn(["applications", "entities", "applications"]);

export const applicationsOrder = (state: any) =>
  state.getIn(["applications", "entitiesOrder"]);
  
const statusFilter = (state: any) =>
  state.getIn(["applications", "ui", "statusFilter"]);

const bizNameFilter = (state: any) =>
  state.getIn(["applications", "ui", "bizNameFilter"]);

const stateFilter = (state: any) =>
  state.getIn(["applications", "ui", "stateFilter"]);

export const selectedApplication = createSelector(
  selectApplicationsById,
  selectedApplicationId,
  (entities, selectedId) => {
    const selected = entities.get(selectedId);
    return selected;
  }
);

export const selectFilteredApplications = createSelector(
  applicationsData,
  applicationsOrder,
  statusFilter,
  bizNameFilter,
  stateFilter,
  (data, order, status, name, state) => {
	if(!data || !order) {
		return [];
	}
    data = data.toJS();
	order = order.toJS();	
	console.log(order);
	console.log(data);
    return order.map((index:any) => data[index]).filter((element: any) => {
      const bizName =
        get(element, "business_name", "") == null
          ? ""
          : get(element, "business_name", "");

      return (
        (status === "" || element.status === status) &&
        bizName.toLowerCase().includes(name.toLowerCase()) &&
        (state == "" || element.state == state)
      );
    });
  }
);
