import { userConstants } from "constants/user";
import { fromJS } from "immutable";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? fromJS({ loggedIn: true, user, logginIn: false, error: null })
  : fromJS({ loggedIn: false, user: {}, logginIn: false, error: null });

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return state.set("logginIn", true).set("user", fromJS(action.user));
    case userConstants.LOGIN_SUCCESS:
      return state
        .set("loggedIn", true)
        .set("logginIn", false)
        .set("user", fromJS(action.user));
    case userConstants.LOGIN_FAILURE:
      return state.set("logginIn", false).set("error", fromJS(action.error));
    case userConstants.LOGOUT:
      return state.set("logginIn", false).set("user", fromJS({})).set("logginIn", false).set("error", null);
    default:
      return state;
  }
}
