import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userActions } from "actions/user";
import bgImage from "assets/images/sign.jpg";
import logo from "assets/images/logo.png";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import toJS from "components/hoc/toJS";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    height: "100vh",
    background: `url(${bgImage}) no-repeat center center`,
    backgroundSize: "cover",
    "&:before": {
      content: "''",
      width: "100%",
      height: "100%",
      display: "flex",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      position: "absolute",
      zIndex: "1",
      top: "0",
      left: "0"
    }
  },
  rightColumn: {
    zIndex: "2",
    backgroundColor: "white",
    padding: "100px 0 0 80px"
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "300px"
  },
  loading: {
    position: "absolute",
    left: "0",
    right: "0",
    top: "60px",
    margin: "0 auto"
  },
  footer: {
    position: "relative"
  },
  button: {
    marginTop: "20px",
    fontSize: "14px",
    padding: "12px 37px"
  }
});

class Login extends PureComponent {
  state = {
    user: {
      username: "",
      password: "",
	  code: ""
    },
    errors: {}
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  handleChange = (key, value) => {
    this.setState(state => ({
      user: {
        ...state.user,
        [key]: value
      },
      errors: {
        ...state.errors,
        [key]: false
      }
    }));
  };

  handleLogin = event => {
    event.preventDefault();
    const { dispatch } = this.props;
    const { user } = this.state;
    let errors = {};
    if (!user.username) {
      errors.username = true;
    }
    if (!user.password) {
      errors.password = true;
    }

    if (Object.keys(errors).length === 0) {
      dispatch(userActions.login(user));
    } else {
      this.setState({
        errors
      });
    }
  };
  
  handle2FALogin = event => {
    event.preventDefault();
    const { dispatch, tfa } = this.props;
    const { user } = this.state;
    let errors = {};
    if (!user.username) {
      errors.username = true;
    }
    if (!user.password) {
      errors.password = true;
    }
	if (!user.code) {
      errors.code = true;
    }
	
    if (Object.keys(errors).length === 0) {
      dispatch(userActions.login({...user, secret: tfa.secret}));
    } else {
      this.setState({
        errors
      });
    }
  };
  
  render() {
    const { user, errors } = this.state;
    const { classes, logginIn, loginError, tfa, user: userProp } = this.props;
    return (
      <Grid container className={classes.container} justify="flex-end">
        <Grid item md={4} className={classes.rightColumn}>
          <Grid>
            <img src={logo} alt="efundex-logo" />
          </Grid>
			{ !tfa &&
				<React.Fragment>
					<Grid>
					  <FormControl
						className={classes.formControl}
						error={errors.username}
						aria-describedby="name-error-text"
					  >
						<InputLabel htmlFor="username-error">Username</InputLabel>
						<Input
						  id="username-error"
						  value={user.username}
						  onChange={e => this.handleChange("username", e.target.value)}
						/>
						{errors.username && (
						  <FormHelperText id="username-error-text">
							This is a required field.
						  </FormHelperText>
						)}
					  </FormControl>
					</Grid>
					<Grid>
					  <FormControl
						className={classes.formControl}
						error={errors.password}
						aria-describedby="name-error-text"
					  >
						<InputLabel htmlFor="password-error">Password</InputLabel>
						<Input
						  id="password-error"
						  type="password"
						  value={user.password}
						  onChange={e => this.handleChange("password", e.target.value)}
						/>
						{errors.password && (
						  <FormHelperText id="password-error-text">
							This is a required field.
						  </FormHelperText>
						)}
					  </FormControl>
					</Grid>
					<Grid item container className={classes.footer} xs={6} justify="center">
					  <Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={this.handleLogin}
						fullWidth
					  >
						{logginIn ? (
						  <CircularProgress
							size={14}
							style={{ color: "white" }}
						  />
						) : (
						  "Sign In"
						)}
					  </Button>
					  <div>
						{loginError && <p style={{ color: "red" }}>{loginError}</p>}
					  </div>
					</Grid>
				</React.Fragment>
			}
			{ tfa && tfa.enabled && 
				<p>Write your 2FA auth code here:</p>
			}
			{ tfa && !tfa.enabled && 
				<div>
					<p>Hello <strong>{userProp.username}!</strong>, to continue you must configure Two-Factor Authentication (2FA) on your account.</p>
					<p>You can scan this QRCode with your preferred 2FA App (Like Google Authenticator, Authy, etc)</p>
					<img src={tfa.qrcode} alt="QR Code" />
					<p>Or manually add this code: {tfa.secret}</p>
					<p>And then, write your auth code here:</p>
				</div>
			}
			{ userProp && //We have a user
				<React.Fragment>
					<FormControl
						className={classes.formControl}
						error={errors.code}
						aria-describedby="code-error-text"
					  >
						<InputLabel htmlFor="code-error">Code</InputLabel>
						<Input
						  id="code-error"
						  type="password"
						  value={user.code}
						  onChange={e => this.handleChange("code", e.target.value)}
						/>
						{errors.code && (
						  <FormHelperText id="code-error-text">
							This is a required field.
						  </FormHelperText>
						)}
					</FormControl>
					<Grid item container className={classes.footer} xs={6} justify="center">
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							onClick={this.handle2FALogin}
							fullWidth
						>
							{logginIn ? (
							  <CircularProgress
								size={14}
								style={{ color: "white" }}
							  />
							) : (
							  "Verify Code"
							)}
						</Button>
						<div>
							{loginError && <p style={{ color: "red" }}>{loginError}</p>}
						</div>
					</Grid>
				</React.Fragment>
			}
					
        </Grid>
      </Grid>
    );
  }
}

Login.propTypes = {
  logginIn: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    logginIn: state.getIn(["auth", "logginIn"]),
    loginError: state.getIn(["auth", "error"]),
	user: state.getIn(["auth", "user", "user"]),
	tfa: state.getIn(["auth", "user", "tfa"]),
  };
};

const connectedLoginPage = connect(mapStateToProps)(toJS(Login));
export default withStyles(styles)(connectedLoginPage);
