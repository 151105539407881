import moment from "moment";

export const formatBusinessYears = (startYear, startMonth = "01") => {
  return moment().diff(moment(`${startYear}${startMonth}`, "YYYYMM"), "years");
};

export const formatShortDate = unformatedDate => {
  return moment(unformatedDate).format("MMM DD YYYY");
};

export const formatMoney = (amount = 0) => {
  const formatOptions = { style: "currency", currency: "USD" };
  const formatedQuantity = new Intl.NumberFormat("en-US", formatOptions);
  // Number(quantity).toLocaleString("en");
  return formatedQuantity.format(amount);
};
