import * as actions from "actions/app";
import { fromJS } from "immutable";

const initialState = fromJS({
  status_codes: []
});

const app = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_STATUSES_SUCCESS:
      const status = action.payload;
      return state.setIn(["status_codes"], fromJS(status));
    default:
      return state;
  }
};

export default app;
