import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { Field } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import { getFormValues, change } from 'redux-form/immutable';

const renderTextField = ({ input, type, meta: { touched, error, warning }, ...props }) => <TextField {...input} type={type} {...props} error={touched && error ? true : null} helperText={touched ? error : null}/>
const required = value => value || value===false ? undefined : 'Required';
export const renderSelectField = ({ input, label, id, options, style, meta: { touched, error, warning }, InputLabelProps, ...rest }) => {
    let optionsList = [];

    if(options && options.length){
        if(typeof options[0] === 'object'){
            optionsList = options.map((option, i) => {
                return <option value={option.value || option.id} key={i}>{option.label}</option>
            });
        } else {
            optionsList = options.map((option, i) => {
                return <option value={option} key={i}>{option}</option>
            });
        }
    } else {
        optionsList = "";
    }


    return <FormControl {...rest} style={{ color: "red", ...style }} error={touched && error ? true : null}>
        <InputLabel htmlFor={id} {...InputLabelProps}>{label}</InputLabel>
        <Select
            autoWidth={true}
            native={true}
            fullWidth={true}
            {...input}
			displayEmpty
            id={id}
            input={<Input {...input} id={id}/>}
			{...rest}
        >
            {optionsList}
        </Select>
    </FormControl>
}

const styles = {
    card: {
        margin:"5px", 
        width: "220px",
        color: '#7F7F7F',
        //height: "880px",
        textAlign: "center"
      },
      button: {
        fontSize: '12px',
        color: 'white',
        backgroundColor: '#FF8700',
        textTransform: 'none',
        marginTop: "40px"
      },
    title: {
      color: '#535353',
      textAlign: "left"
    },
    closeIcon: {
        color: '#FF8700',
        cursor: 'pointer'
    },
    space: {
      display: 'flex',
      justifyContent: 'space-between'
    },
  };


class OfferForm extends Component {
	getPercentageSpecifyValue = () => {
		const { currentValues } = this.props;
		if(Number(currentValues.fundings) && Number(currentValues.factorRate) && Number(currentValues.term) && Number(currentValues.averageMonthly)) {
			let monthlyPayment = ( currentValues.fundings * currentValues.factorRate) / currentValues.term;
			let percentage = Number(monthlyPayment * 100 / currentValues.averageMonthly)
			return (Math.round(percentage*100)/100)+"%";
		} else {
			return "-";
		}
	}
	
	componentDidUpdate = (prevProps) => {
		const { formName, currentValues, change } = this.props;
		if(prevProps.currentValues.paymentType !== currentValues.paymentType){
			console.log("Se actualizo el valor de paymentType de ", prevProps.currentValues.paymentType, "a", currentValues.paymentType);
			//Make sure dependant fields got his default value
			change(formName, "weeks", null); //Reset to default value
			console.log("actalizo weeks a null");
			change(formName, "days", 20); //Reset to default value
			console.log("actalizo days a 20");
		}
	}
	render() {
      const { classes, title, buttonLabel, currentValues } = this.props;
      return (
          <React.Fragment>
            <div>
                <Card className={classes.card}>
                    <CardContent className={classes.gridStyle}>
                        <div className="card-body">

                            <div className={classes.space}>
                                <span>
                                  <h3 className={classes.title}>{title}</h3>
                                </span>
                                <span><CloseIcon onClick={this.props.onClose} className={classes.closeIcon} /></span>
                            </div>
                            <div className="form-body">
                                <Field
                                    name="fundings"
                                    component={renderTextField}
                                    label="Fundings"
                                    placeholder="$80,000"
                                    margin="normal"
                                    fullWidth={true}
                                    validate={[required]}
                                />

                                <Field
                                    name="factorRate"
                                    component={renderTextField}
                                    label="Factor rate"
                                    placeholder="15%"
                                    margin="normal"
                                    fullWidth={true}
                                    validate={[required]}
                                />

                                <Field
                                    name="term"
                                    component={renderTextField}
                                    label="Term (months)"
                                    placeholder="24"
                                    margin="normal"
                                    fullWidth={true}
                                    validate={[required]}
                                />

								{ currentValues.offerType === "CA" &&
									<Field
										name="averageMonthly"
										component={renderTextField}
										label="Average monthly revenue"
										placeholder="$100,000"
										margin="normal"
										fullWidth={true}
										validate={[required]}
									/>
								}
								{currentValues.offerType === "CA" &&
									<p>Percentage specify { this.getPercentageSpecifyValue() }</p>
								}
								
								<Field
									style={{ width: "100%", marginTop: "20px" }}
									name="paymentType"
									component={renderSelectField}
									label="Payment Frequency"
									options={[
										{
											id: "daily",
											label: "Daily"
										},{
											id: "weekly",
											label: "Weekly"
										},{
											id: "both",
											label: "Both"
										}
									]}									
									InputLabelProps={{
										shrink: true
									}}
								/>
								{ currentValues.paymentType !== "daily" &&
									<Field
										name="weeks"
										component={renderTextField}
										label="How many weeks"
										placeholder="10"
										margin="normal"
										fullWidth={true}
										validate={[required]}
									/>
								}
								{ currentValues.paymentType !== "weekly" &&
									<Field
										style={{ width: "100%", marginTop: "20px" }}
										name="days"
										component={renderSelectField}
										label="How many days per month"
										options={[
											{
												id: 20,
												label: "20"
											},{
												id: 21,
												label: "21"
											}
										]}									
										InputLabelProps={{
											shrink: true
										}}
									/>
								}
                                { /* <Button type="button" variant="contained" color="primary" onClick={this.props.onButtonClick} className={classes.button}>{buttonLabel}</Button> */ }
								<Button type="submit" variant="contained" color="primary" className={classes.button}>{buttonLabel}</Button>
                            </div>
                        </div> 
                    </CardContent>
                    
                </Card>
            </div>

                      
      </React.Fragment>
      );
  }
}

OfferForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
	currentValues: getFormValues(ownProps.formName)(state)	
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	change: (form, field, value) => dispatch(change(form, field, value))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OfferForm));



