import { normalize } from 'normalizr';
import customersService from "./../services/customers";
import { customerDetailsSchema } from './../schemas/schemas';
import { Dispatch } from "redux";

export const FETCH_CUSTOMER_DETAILS_REQUEST = "FETCH_CUSTOMER_DETAILS_REQUEST";
export const FETCH_CUSTOMER_DETAILS_SUCCESS = "FETCH_CUSTOMER_DETAILS_SUCCESS";
// export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

const fetchCustomerDetailsRequest = () => ({
  type: FETCH_CUSTOMER_DETAILS_REQUEST
});

// const fetchCustomerDataFailure = (payload) => ({
//     type: FETCH_CUSTOMERS_FAILURE,
//     payload
// });

const fetchCustomerDetailsSuccess = (payload: any) => ({
  type: FETCH_CUSTOMER_DETAILS_SUCCESS,
  payload
});

export const fetchCustomerDetails = (id: Number) => (dispatch: Dispatch) => {
  dispatch(fetchCustomerDetailsRequest());
  customersService.fetchCustomerDetails(id).then(data => {
	dispatch(fetchCustomerDetailsSuccess(data));
  });
};
