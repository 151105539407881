import React, {Component} from 'react';
import { reduxForm } from 'redux-form/immutable';
import OfferForm from './OfferForm';

class CreateOffer extends Component {
	render() {
		const { classes, onClose, handleSubmit } = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<OfferForm classes={classes} title="Create an offer" onClose={onClose} buttonLabel="Create Offer" formName="CreateOffer"/>
			</form>
		);
	}
}

CreateOffer = reduxForm({
	form: 'CreateOffer',
	destroyOnUnmount: false,
	initialValues: {
		paymentType: "both",
		days: 20
	}
})(CreateOffer);

export default CreateOffer;



