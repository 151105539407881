import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CreateOffer from './CreateOffer';
import EditOffer from './EditOffer';
import Offer from './Offer';
import AddBox from './AddBox';
import { selectedApplication } from "selectors/applications";
import { selectStatus } from "selectors/app";
import { connect } from "react-redux";
import {
  setCurrentAplication,
  getApplicationById,
  addOfferToApplication,
  deleteOfferFromApplication,
  editOfferFromApplication,
  sendOffersToCustomer,
} from "actions/applications.ts";
import { fetchStatuses } from "actions/app";
import get from "lodash.get";
import {
  formatShortDate,
  formatMoney
} from "utils/format";
import config from "config/index.ts";
import { reset, initialize, change, getFormValues } from 'redux-form/immutable';
import swal from "sweetalert";
import moment from 'moment';

const styles = {
	cardHeader: {
      padding: "10px", 
    },
    card: {
      margin:"5px 0", 
      width: "220px",
      color: "#7F7F7F", 
      padding: "10px", 
    },
    card2: {
        margin:"10px", 
		padding: "10px", 
        width: "300px",
        backgroundColor: '#E4E4E4',
        //height: "710px",
        textAlign: "center"
      },
      button: {
        fontSize: '12px',
        color: 'white',
        backgroundColor: '#FF8700',
        textTransform: 'none',
        //marginTop: "12px"
      },
      buttonCard: {
        width: '80%',
        fontSize: '12px',
        color: '#FF8700',
        borderColor: '#FF8700',
        margin: '5px auto',
        display: 'block'
      },
      ButtonGroup: {
        marginBottom: '50px'
    },
      viewDoc: {
        color: '#FF8700',
        marginBottom: '20px',
        fontSize: '14px'
      },
      viewDocSpace: {
        //  marginLeft: '30px'
      },
    title: {
      color: '#535353'
    },
    title2: {
        margin: '20px 0 5px 0',
        color: '#535353',
        fontSize: '14px'
      },
    content: {
        padding: "0px", 	
		"&:last-child":{
			padding: "0px", 	
		}
    },
    pos: {
      marginBottom: '20px',
      color: '#878787',
      fontSize: '14px'
    },

      space: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      space2: {
        display: 'flex',
      },
      addIcon: {
          marginTop: '250px',
          color: '#878787',
          width: 50,
          height: 50,
      },
    addOffer: {
        height: '600px'
    },
    arrow: {
        marginTop: "11px",
        cursor: "pointer"
    },
	iconDoc: {
		verticalAlign: "middle",
		cursor: "pointer"
	},
	docName: {
		verticalAlign: "middle",
		cursor: "pointer"
	},
	maxFundingAmountValue: {
		fontSize: "2em"
	}
  };

class AddOffer extends Component {
    state = { 
		showCard: false,
		editingId: null,
		currentTab: "BL"
    }
	
	componentDidMount() {
		const {
		  selectApplication,
		  getApplication,
		  getStatus,
		} = this.props;
		
		getStatus();
		selectApplication();
		getApplication();
		this.resetCreateOfferForm();
	}

    onAddClick = () => {
        this.setState({ 
            showCard: true
        });
		this.resetCreateOfferForm();
    }

    onCreateClose = () => {
        this.setState({ 
            showCard: false
        });
    }
	
	resetCreateOfferForm(){
		const { resetForm, change } = this.props;
		const { currentTab } = this.state;
		resetForm("CreateOffer");
		change("CreateOffer", "offerType", currentTab);
	}

    onCreateOfferClick = (values) => {
        const { createOffer, application, resetForm } = this.props;
		const { currentTab } = this.state;
		const loanAppId = get(application, "loan_app.id");
		const offerValues = {
			...values,
			offerType: currentTab,
			days: values.paymentType === "weekly" ? null : values.days,
			weeks: values.paymentType === "daily" ? null : values.weeks,
		}
		
		loanAppId && createOffer(loanAppId, offerValues)
			.then(() => {
				this.setState({ showCard: false });
				resetForm("CreateOffer");
			});
    }

    onDeleteOffer = (offer) => {
        const { deleteOffer, application } = this.props;
		const loanAppId = get(application, "loan_app.id");
		
		loanAppId && deleteOffer(loanAppId, offer.id);
    }

	onEditClose = () => {
		this.setState({editingId: null});
	}
	
	onEditOfferClick = (values) => {
        const { editOffer, application } = this.props;
		const { editingId, currentTab } = this.state;
		const loanAppId = get(application, "loan_app.id");
		const offerValues = {
			...values,
			offerType: currentTab,
			days: values.paymentType === "weekly" ? null : values.days,
			weeks: values.paymentType === "daily" ? null : values.weeks,
		}
		loanAppId && editOffer(loanAppId, editingId, offerValues)
			.then(() => {
				this.setState({ editingId: null });
			});
    }
	
	onEditOffer = (offer) => {
		const { initializeForm } = this.props;
		const { currentTab } = this.state;
		this.setState({ editingId: offer.id });
		initializeForm("EditOffer", {
			fundings: offer.fundings,
			factorRate: offer.factorRate,
			term: offer.term,
			averageMonthly: offer.averageMonthly,
			paymentType: offer.paymentType,
			days: offer.days,
			weeks: offer.weeks,
			offerType: currentTab
		});
	}
        
    orderOffers = (a, b) => {
        return b.fundings - a.fundings  
    }
	
    displayOffers = () => {
        const { application } = this.props;
        const { editingId, currentTab } = this.state;
        const offers = get(application, "loan_app.offers", [])
		if(!offers.length){
			return null;
		}		
		
		const filteredOffers = offers.filter(offer => offer.offerType === currentTab);
        
		return filteredOffers.sort(this.orderOffers).map(offer => 
			<div key={offer.id}>
				{ offer.id === editingId ?
					<EditOffer onClose={this.onEditClose} onSubmit={this.onEditOfferClick} /> 
				:
					<Offer onDelete={this.onDeleteOffer} onEdit={this.onEditOffer} offer={offer} />
				}
			</div>
		);
    }

    handleSearch = () => {
        const { match: { params: { id } } } = this.props;
        this.props.history.push(`/admin/application/${id}`)
    }

	downloadStatement = statement => {
		const { token } = this.props;
		window.location.assign(
		  `${config.apiUrl}/report-statement-file?id=${statement.id}&token=${token}`
		);
	};

	downloadApplicationFile = () => {
		const { application, token } = this.props;
		const id = get(application, "loan_app.id", 0);
		window.location.assign(
		  ` ${config.apiUrl}/report-loanapp?id=${id}&token=${token}`
		);
	};
	
	getMaxFundingAmount = () => {
		const { application } = this.props;
		const offers = get(application, "loan_app.offers", [])
		let maxFundings = null;
        offers.forEach(offer => {
			if(maxFundings === null || offer.fundings > maxFundings){
				maxFundings = offer.fundings;
			}
		});
		return maxFundings;
	}
	
	onSendOffersClick = () => {
		const { sendOffers, application } = this.props;
		const loanAppId = get(application, "loan_app.id");
		loanAppId && sendOffers(loanAppId).then(() => {
			swal({
				icon: 'success',
				//className: 'text-center',
				text: `Offers were sent to customer successfully`
			});
		});
	}
	
	onChangeTab = (evt, value) => {
		this.setState({ 
			currentTab: value,
			showCard: false
		}, () => {
			this.resetCreateOfferForm();
		});		
	}
	  
    render() {
        const { classes, application, token } = this.props;
        const { showCard, currentTab } = this.state;
		const maxFundingAmount = this.getMaxFundingAmount();
		const hasOffers = application && application.loan_app && application.loan_app.offers && application.loan_app.offers.length > 0;
		const BLOffersCount = hasOffers ? application.loan_app.offers.filter(offer => offer.offerType === "BL").length : 0;
		const CAOffersCount = hasOffers ? application.loan_app.offers.filter(offer => offer.offerType === "CA").length : 0;
		const canAddMoreOffers = currentTab === "BL" ? BLOffersCount < 4 : CAOffersCount < 4;
		console.log("Application", hasOffers ? application.loan_app.offers : null, BLOffersCount, CAOffersCount);
		return (
            <React.Fragment>
				{ /* The following div is a temporary hack, to load CreateOffer component before using it */ }
				<div style={{ display: "none"}}>
					<CreateOffer/> 
				</div>
                <Grid container spacing={24}>
                    <Grid item xs>
                        <Card className={classes.cardHeader}>
                            <CardContent className={classes.content}>
                                <div className={classes.space}>
                                <ArrowBack onClick={this.handleSearch} className={classes.arrow}/>
								<Tabs value={currentTab} onChange={this.onChangeTab}>
									<Tab label={`Business Loan (${BLOffersCount})`} value="BL"/>
									<Tab label={`Cash Advance (${CAOffersCount})`} value="CA"/>
								</Tabs>
								<Button variant="contained" color="primary" className={classes.button} onClick={this.onSendOffersClick} disabled={!hasOffers}>
									Send Offer { application && application.loan_app && application.loan_app.sentToReviewDate ? "Changes" : null }
								</Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <div className={classes.space2}>
                    <div>
                        <Card className={classes.card}>
                            <CardContent  className={classes.content}>
                                <h4 style={{textAlign: "center"}} className={classes.title}>
                                    Max funding amount
                                </h4>
                                <p style={{textAlign: "center"}} className={classes.pos}>
                                    <span className={classes.maxFundingAmountValue}>{maxFundingAmount ? formatMoney(maxFundingAmount) : "-" }</span>
                                </p>
                                    
                            </CardContent>
                            
                        </Card>
                    
                        <Card className={classes.card}>
							<CardContent className={classes.content}>
							  <h4 className={classes.title}>Application info</h4>
							  <p className={classes.pos}>
								<h4 className={classes.title2}>Date applied:</h4>
								{ application && application.loan_app
								  ? formatShortDate(moment.unix(get(application, "loan_app.createdAt")))
								  : "-"}
							  </p>
							  <p className={classes.pos}>
								<h4 className={classes.title2}>Loan purpose:</h4>
								{application && application.loan_app_short
								  ? get(
									  application,
									  "loan_app_short.loan_purpose",
									  []
									).toString()
								  : "-"}
							  </p>
							  <h4 className={classes.title2}>Funding requested</h4>
								{formatMoney(get(application, "loan_app_short.funding", 0))}
							  
							  { application && application.loan_app &&
								get(application, "loan_app.statements", []) && 
								get(application, "loan_app.statements", []).length > 0 &&
								<React.Fragment>
									<h4 className={classes.title2}>Statements</h4>
									{application && application.loan_app &&
									  get(application, "loan_app.statements", []).map((statement, i) => (
										<div className={classes.spaceDoc} key={statement.id}>
											<p
											  className={classes.viewDocSpace}
											  onClick={this.downloadStatement.bind(this, statement)}
											>
												<AssignmentIcon
												className={classes.iconDoc}
												onClick={this.downloadStatement.bind(this, statement)}
											  />
											  <span className={classes.docName}>{statement.originalFilename}</span>
											</p>
										</div>
									  ))}
								</React.Fragment>
							  }
							</CardContent>
							<div className={classes.ButtonGroup}>
							  <Button
								variant="outlined"
								size="large"
								color="primary"
								className={classes.buttonCard}
								onClick={this.downloadApplicationFile}
							  >
								Download application pdf
							  </Button>
							  { application && application.user && application.user.hasQuickbooks &&
									<React.Fragment>
										<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${application.user.id}/quickbooks-info/report/aged-receivable-detail?token=${token}`}>QB - Aged Receivable Detail</Button>
										<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${application.user.id}/quickbooks-info/report/aged-payable-detail?token=${token}`}>QB - Aged Payable Detail</Button>
										<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${application.user.id}/quickbooks-info/report/profit-and-loss?token=${token}`}>QB - Profit and Loss</Button>
										<Button variant="outlined" size="large" color="primary" className={classes.buttonCard} href={`${config.apiUrl}/${application.user.id}/quickbooks-info/report/balance-sheet?token=${token}`}>QB - Balance Sheet</Button>
									</React.Fragment>
								}
							  {/*<Button
								variant="outlined"
								size="large"
								color="primary"
								className={classes.buttonCard}
							  >
								See bank connection
							  </Button>*/}
							</div>
						</Card>
                    </div>

                    { canAddMoreOffers && !showCard && 
                        <AddBox onClick={this.onAddClick}/> 
                    }
                    
                    { canAddMoreOffers && showCard &&
                        <CreateOffer onClose={this.onCreateClose} onSubmit={this.onCreateOfferClick}/> 
                    }

                    { this.displayOffers() }
                </div>                        
			</React.Fragment>
        );
    }
}

AddOffer.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  application: selectedApplication(state),
  status: selectStatus(state),
  token: state.getIn(["auth", "user", "token"]),
  applicationOffers: state.getIn(["applications", "entities", "applicationsOffersById"]),
  editOfferValues: getFormValues("EditOffer")(state) 
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { id }
    }
  } = ownProps;
  return {
    selectApplication: () => dispatch(setCurrentAplication(id)),
    getApplication: () => dispatch(getApplicationById(id)),
    getStatus: () => dispatch(fetchStatuses()),
	createOffer: (loanAppId, values) => dispatch(addOfferToApplication(loanAppId, values)),
	deleteOffer: (loanAppId, offerId) => dispatch(deleteOfferFromApplication(loanAppId, offerId)),
	editOffer: (loanAppId, offerId, values) => dispatch(editOfferFromApplication(loanAppId, offerId, values)),
	resetForm: (formName) => dispatch(reset(formName)),
	initializeForm: (formName, data) => dispatch(initialize(formName, data)),
	sendOffers: (loanAppId) => dispatch(sendOffersToCustomer(loanAppId)),
	change: (form, field, value) => dispatch(change(form, field, value))
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(AddOffer));
