import { createStyles, Theme } from "@material-ui/core/styles";

export default ({ spacing }: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: '0!important',
      margin: '0 auto!important',
      maxWidth: '95%',
    },
    content: {
      display: "inline-block",
      padding: spacing.unit * 1.5,
      position: "relative"
    },
    root: {
      flexGrow: 1
    },
    closeBtn: {
      position: "absolute",
      right: "5px",
      top: "5px"
    },
    title: {
      marginTop: "30px"
    }
  });
