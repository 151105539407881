import api from "../config/api";
import { AxiosResponse } from "axios";
import { DraftNote } from "../types/Note";
import { DraftOffer } from "../types/Offer";

const fetchApplicationData = async (): Promise<Array<any>> => {
  try {
    const response: AxiosResponse = await api.get("/report-loanapp-status");
    return response.data;
  } catch (error) {
    throw console.error(`Error fetching aplications: ${error.message}`);
  }
};

const fetchApplicationById = async (id: Number | String): Promise<any> => {
  try {
    const response: AxiosResponse = await api.get(
      `/report-detail-loanapp?id=${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching aplication: ${error.message}`);
  }
};

const fetchApplicationOffersById = async (
  id: Number | String
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.get(
      `/report-offer-loanapp?id=${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching aplication: ${error.message}`);
  }
};

const changeApplicationStatus = async (
  id: Number | String,
  status: Number | String
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.post(
      `/set-status-loanapp/${id}`,
      {
        status
      }
    );
    return response.data;
  } catch (error) {
    throw console.error(`Error fetching aplication status: ${error.message}`);
  }
};

const addNoteToApplication = async (
  id: Number | String,
  note: DraftNote
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.post(
      `/create-note-customer-loanapp/${id}`,
      note
    );
    return response.data;
  } catch (error) {
    throw console.error(`Error updating aplication status: ${error.message}`);
  }
};

const addOfferToApplication = async (
  id: Number | String,
  offer: DraftOffer
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.post(
      `/application/${id}/offer`,
      offer
    );
    return response.data;
  } catch (error) {
    throw console.error(`Error updating aplication status: ${error.message}`);
  }
};

const deleteOfferFromApplication = async (
  applicationId: Number | String,
  offerId: DraftOffer
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.delete(
      `/application/${applicationId}/offer/${offerId}`
    );
    return response.data;
  } catch (error) {
    throw console.error(
      `Error deleting offer from application status: ${error.message}`
    );
  }
};

const editOfferFromApplication = async (
  applicationId: Number | String,
  offerId: Number | String,
  offer: DraftOffer
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.put(
      `/application/${applicationId}/offer/${offerId}`,
      offer
    );
    return response.data;
  } catch (error) {
    throw console.error(`Error updating aplication status: ${error.message}`);
  }
};

const sendOffersToCustomer = async (
  applicationId: Number | String
): Promise<any> => {
  try {
    const response: AxiosResponse = await api.post(
      `/application/${applicationId}/send-offers`
    );
    return response.data;
  } catch (error) {
    throw console.error(`Error updating aplication status: ${error.message}`);
  }
};

export default {
  fetchApplicationData,
  fetchApplicationById,
  changeApplicationStatus,
  addNoteToApplication,
  fetchApplicationOffersById,
  addOfferToApplication,
  deleteOfferFromApplication,
  editOfferFromApplication,
  sendOffersToCustomer
};
