import * as actions from "actions/customerDetails";
import { fromJS } from "immutable";

const initialState = fromJS({
  ui: {
    loading: false
  },
  customerDetails: null
});

const customers = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CUSTOMER_DETAILS_REQUEST:
      return state.setIn(["ui", "loading"], true)
		.setIn(["customerDetails"], null);
    case actions.FETCH_CUSTOMER_DETAILS_SUCCESS: {
      return state
        .setIn(["ui", "loading"], false)
        .setIn(["customerDetails"], fromJS(action.payload));
    }
    default:
      return state;
  }
};

export default customers;
