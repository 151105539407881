import React, {Component} from 'react';
import { reduxForm } from 'redux-form/immutable';
import OfferForm from './OfferForm';

class EditOffer extends Component {
  render() {
		const { classes, onClose, handleSubmit } = this.props;
		return (
			<form onSubmit={handleSubmit}>
				<OfferForm classes={classes} title="Editing offer" onClose={onClose} buttonLabel="Edit Offer" formName="EditOffer"/>
			</form>
		);
  }
}

EditOffer = reduxForm(
  {
      form: 'EditOffer',
      destroyOnUnmount: false
  })(EditOffer);


  export default EditOffer;


