import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import whiteLogo from 'assets/images/white-logo.png';

const styles = {
    root: {
        flexGrow: 1,
    },
    flex: {
        flexGrow: 1,
    },
    appBar: {
      background: 'linear-gradient(to right, #F28C1D, #FFBA00)',
	  boxShadow: "none"
    },
    menuButton: { 
        marginLeft: -12,
        marginRight: 20,
    },
    logo: {
        background: `url(${whiteLogo}) no-repeat right`,
        paddingLeft: '40px',
        height: '40px',
        width: '200px'
    }
};

class MenuAppBar extends React.Component {
    state = {
        auth: true,
        anchorEl: null,
    };

    render() {
        const { classes } = this.props;
        return (
            <AppBar
                position="absolute"
                className={classes.appBar}
            >
                <Toolbar>
                    <div className={classes.logo} />
                </Toolbar>
            </AppBar>
        );
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuAppBar);
