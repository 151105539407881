import React, { PureComponent, ChangeEvent } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { states } from "../../helpers/selectData";
import TableFilter from "../../components/Tables/TableFilter";
import TableEmptyRows from "../../components/Tables/TableEmptyRows";
import { formatMoney } from "../../utils/format";
import {
  fetchLoginLogData,
  filterLoginLogData
} from "../../actions/loginLog";
import config from "../../config/index";
import { push } from "connected-react-router";
import toJS from "../../components/hoc/toJS";
import styles from "../../utils/sharedTableStyles";

import { selectFilteredLoginLog } from "../../selectors/loginLog";

interface ComponentProps {
  data: Array<any>;
  dispatch: Dispatch<any>;
  token: string;
  loading: boolean;
  classes: any;
  status_codes: string[];
  stateFilter: string;
  bizNameFilter: string;
  statusFilter: string;
}

class LoginLog extends PureComponent<ComponentProps> {
  state = {
    page: 0,
    rowsPerPage: 10
  };

  handleChangePage = (_: any, page: number) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchLoginLogData());
  }

  goToApplication = (id: number) => {
    const { dispatch } = this.props;
    dispatch(push(`/admin/application/${id}`));
  };

  downloadApplication = (applicationId: number) => {
    const { token } = this.props;
    window.location.assign(
      `${config.apiUrl}/report-loanapp?id=${applicationId}&token=${token}`
    );
  };

  handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { dispatch } = this.props;
    const {
      target: { name, value }
    } = event;
    dispatch(filterLoginLogData(name, value));
  };

  render() {
    const {
      classes,
      data,
      loading,
      status_codes,
      stateFilter,
      bizNameFilter,
      statusFilter
    } = this.props;
    const { rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={classes.root}>
        <h2 className={classes.titleStyle}>Login Log</h2>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.head}>
              <TableCell
                className={`${classes.headStyle} ${classes.dateCell}`}
                padding="checkbox"
              >
                Datetime
              </TableCell>
              <TableCell
                className={`${classes.headStyle} ${classes.dateCell}`}
                padding="none"
              >
                User
              </TableCell>
              <TableCell
                className={`${classes.headStyle} ${classes.statusCell}`}
                padding="none"
              >
                IP
              </TableCell>                     
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  className={classes.selectable}
                  onClick={() => this.goToApplication(row.id_loan_app)}
                >
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {moment(row.createdAt).format("MMM DD YYYY hh:mm:ss a")}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {row.user.username}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {row.ip}
                  </TableCell>
                </TableRow>
              ))}
            <TableEmptyRows
              emptyRows={emptyRows}
              loading={loading}
              dataLength={data.length}
              colSpan={10}
            />
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = (state: any) => ({
  data: selectFilteredLoginLog(state),
  statusFilter: state.getIn(["applications", "ui", "statusFilter"]),
  bizNameFilter: state.getIn(["applications", "ui", "bizNameFilter"]),
  stateFilter: state.getIn(["applications", "ui", "stateFilter"]),
  loading: state.getIn(["applications", "ui", "loading"]),
  status_codes: state.getIn(["app", "status_codes"]),
  states: state.getIn(["app", "states"]),
  token: state.getIn(["auth", "user", "token"])
});

export default connect(mapStateToProps)(withStyles(styles)(toJS(LoginLog)));
