import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router/immutable";
import Immutable from "immutable";
import thunk from "redux-thunk";
import createHistory from "history/createBrowserHistory";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import rootReducer from "reducers/rootReducer";

export const history = createHistory();

const initialState = Immutable.Map();
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
  loadingBarMiddleware({
    promiseTypeSuffixes: ["REQUEST", "SUCCESS", "FAILURE"]
  })
];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);

export default store;
