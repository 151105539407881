import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) => createStyles({
  card: {
    //minWidth: 275,
    color: "#7F7F7F",
    overflowWrap: "break-word"
  },
  title: {
    color: "#535353"
  },
  title2: {
    marginTop: "30px",
    color: "#535353",
    fontSize: "14px"
  },
  pos: {
    marginTop: "28px",
    marginBottom: "20px",
    color: "#878787",
    fontSize: "14px"
  },
  space: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  addIcon: {
    color: theme.palette.primary.main,
  },
  noteStatus: {
    color: "#23BCAC",
    fontSize: "14px",
    marginTop: "-10px",
    paddingLeft: "5px",
    borderLeft: "1px solid #23BCAC"
  }
});
