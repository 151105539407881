import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

class Index extends PureComponent {
  componentDidMount() {
    const { loggedIn, dispatch } = this.props;
    if (loggedIn) {
      dispatch(push("/admin/applications"));
    } else {
      dispatch(push("/login"));
    }
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  loggedIn: state.getIn(["auth", "loggedIn"])
});

export default connect(mapStateToProps)(Index);
