import { fromJS } from "immutable";
import * as actions from "../actions/applications";
import ActionType from "../types/Actions";

export const initialState = fromJS({
  ui: {
    loading: false,
    statusFilter: "",
    bizNameFilter: "",
    stateFilter: "",
    selected: null,
    newNoteModal: false
  },
  entities: {
    applications: {},
    applicationsById: {},
    applicationsOffersById: {}
  }
});

const applications = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actions.FETCH_APPLICATIONS_REQUEST:
      return state.setIn(["ui", "loading"], true);
    case actions.FETCH_APPLICATIONS_SUCCESS: {
      const { result, entities: { applications} } = action.payload;
      return state
        .setIn(["ui", "loading"], false)
        .setIn(["entities", "applications"], fromJS(applications))
		.setIn(["entitiesOrder"], fromJS(result));
    }
    case actions.SET_CURRENT_APPLICATION: {
      return state.setIn(["ui", "selected"], action.payload);
    }
    case actions.GET_APPLICATION_BY_ID_SUCCESS:
    case actions.ADD_OFFER_TO_APPLICATION_SUCCESS:
    case actions.DELETE_OFFER_FROM_APPLICATION_SUCCESS:
    case actions.EDIT_OFFER_FROM_APPLICATION_SUCCESS: {
      const { id, data } = action.payload;
      return state.updateIn(
        ["entities", "applicationsById"],
        (applications: any) => applications.set(`${id}`, data)
      );
    }
    case actions.ADD_NOTE_TO_APPLICATION_SUCCESS: {
      const { id, data } = action.payload;
      const newState = state.updateIn(
        ["entities", "applicationsById"],
        (applications: any) => applications.setIn([`${id}`, "loan_notes"], data)
      );
      debugger;
      return newState;
    }
    case actions.TOGGLE_NEW_APPLICATION_MODAL:
      return state.updateIn(
        ["ui", "newNoteModal"],
        (status: boolean) => !status
      );
    case actions.FILTER_APPLICATION_DATA:
      return state.setIn(
        ["ui", `${action.payload.key}Filter`],
        action.payload.value
      );
    default:
      return state;
  }
};

export default applications;
