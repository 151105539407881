import api from "../config/api";
import { AxiosResponse } from "axios";

const fetchCustomerData = async () => {
  let response: AxiosResponse = await api.get("/report-customer");
  return response.data;
};

const fetchCustomerDetails = async (id: Number) => {
  let response: AxiosResponse = await api.get(`/customer/${id}`);
  return response.data;
};

export default {
  fetchCustomerData,
  fetchCustomerDetails
};
