import { normalize } from 'normalizr';
import customersService from "../services/customers.ts";
import { customerSchema } from 'schemas/schemas';

export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
// export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

const fetchCustomerDataRequest = () => ({
  type: FETCH_CUSTOMERS_REQUEST
});

// const fetchCustomerDataFailure = (payload) => ({
//     type: FETCH_CUSTOMERS_FAILURE,
//     payload
// });

const fetchCustomerDataSuccess = payload => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  payload
});

export const fetchCustomerData = () => dispatch => {
  dispatch(fetchCustomerDataRequest());
  customersService.fetchCustomerData().then(data => {
    const normalizedData = normalize(data, [customerSchema]);
    dispatch(fetchCustomerDataSuccess(normalizedData.entities));
  });
};

export const FILTER_CUSTOMERS_DATA = "FILTER_CUSTOMERS_DATA";

export const filterCustomersData = (key, value) => ({
  type: FILTER_CUSTOMERS_DATA,
  payload: {
    key,
    value
  }
});
