import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import TableFilter from "components/Tables/TableFilter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "utils/sharedTableStyles.ts";

import TablePaginationActionsWrapped from "components/Tables/TablePaginationActions";

import { fetchCustomerData, filterCustomersData } from "actions/customers";
import config from "config/index.ts";
import TableEmptyRows from "../../components/Tables/TableEmptyRows";

import { selectFilteredCustomers } from "../../selectors/customers";
class Customers extends PureComponent {
  state = {
    rowsPerPage: 10,
    page: 0
  };

  handleChangePage = (_, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleFilterChange = event => {
    const { dispatch } = this.props;
    const {
      target: { name, value }
    } = event;
    dispatch(filterCustomersData(name, value));
  };

  goToCustomer = id => {
    const { dispatch } = this.props;
    dispatch(push(`/admin/customer/${id}`));
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchCustomerData());
  }

  renderUTMTags = utmTags => {
    return utmTags ? (
      <React.Fragment>
        {utmTags.utm_source && (
          <div>
            <b>Source:</b> {utmTags.utm_source}
          </div>
        )}
        {utmTags.utm_campaign && (
          <div>
            <b>Campaign:</b> {utmTags.utm_campaign}
          </div>
        )}
        {utmTags.utm_medium && (
          <div>
            <b>Medium:</b> {utmTags.utm_medium}
          </div>
        )}
        {utmTags.utm_content && (
          <div>
            <b>Content:</b> {utmTags.utm_content}
          </div>
        )}
        {utmTags.utm_term && (
          <div>
            <b>Term:</b> {utmTags.utm_term}
          </div>
        )}
      </React.Fragment>
    ) : null;
  };

  render() {
    const { classes, data, token, loading, nameFilter } = this.props;
    const { rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={classes.root}>
        <h2 className={classes.titleStyle}>Customers</h2>
        <div className={classes.toolbar}>
          <TableFilter
            onChange={this.handleFilterChange}
            filter="name"
            label="Name"
            type="input"
            value={nameFilter}
          />
        </div>
        <Table className={classes.table} padding="none">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.headStyle} padding="checkbox">
                Date created
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Name
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Company
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Email
              </TableCell>
              <TableCell
                className={classes.headStyle}
                padding="checkbox"
                style={{ paddingRight: "70px" }}
              >
                Phone
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                IP Address
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Quickbooks Reports
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                UTMTags
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .sort((a, b) => (b.date_create < a.date_create ? -1 : 1))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  className={classes.selectable}
                  onClick={() => this.goToCustomer(row.user_id)}
                >
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {moment(row.date_create).format("MMM DD YYYY")}
                  </TableCell>
                  <TableCell
                    className={classes.cellStyle}
                    padding="checkbox"
                  >{`${row.firstName} ${row.lastName}`}</TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.company}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.email}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.phone}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.ipUser}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.quickbooks_id && (
                      <p>
                        <a
                          href={`${config.apiUrl}/${
                            row.user_id
                          }/quickbooks-info/report/aged-receivable-detail?token=${token}`}
                          style={{ color: "#FFBB00" }}
                        >
                          AgedReceivableDetail
                        </a>
                        {" | "}
                        <a
                          href={`${config.apiUrl}/${
                            row.user_id
                          }/quickbooks-info/report/aged-payable-detail?token=${token}`}
                          style={{ color: "#FFBB00" }}
                        >
                          AgedPayableDetail
                        </a>
                        {" | "}
                        <a
                          href={`${config.apiUrl}/${
                            row.user_id
                          }/quickbooks-info/report/profit-and-loss?token=${token}`}
                          style={{ color: "#FFBB00" }}
                        >
                          ProfitAndLoss
                        </a>
                        {" | "}
                        <a
                          href={`${config.apiUrl}/${
                            row.user_id
                          }/quickbooks-info/report/balance-sheet?token=${token}`}
                          style={{ color: "#FFBB00" }}
                        >
                          BalanceSheet
                        </a>
                      </p>
                    )}
                    {!row.quickbooks_id && <p>N/A</p>}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.utmTags
                      ? this.renderUTMTags(row.utmTags)
                      : "Not available"}
                  </TableCell>
                </TableRow>
              ))}
            <TableEmptyRows
              emptyRows={emptyRows}
              loading={loading}
              dataLength={data.length}
              colSpan={7}
            />
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={2}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

Customers.propTypes = {
  data: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  nameFilter: state.getIn(["customers", "ui", "nameFilter"]),
  data: selectFilteredCustomers(state),
  loading: state.getIn(["customers", "ui", "loading"]),
  token: state.getIn(["auth", "user", "token"])
});

export default connect(mapStateToProps)(withStyles(styles)(Customers));
