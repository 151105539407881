import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
// import TableFilter from "components/Tables/TableFilter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import TablePaginationActionsWrapped from "components/Tables/TablePaginationActions";

import { fetchLeadsData, filterLeadsData } from "actions/leads";
import TableEmptyRows from "../../components/Tables/TableEmptyRows";
import styles from "utils/sharedTableStyles.ts";

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      page: 0
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleFilterChange = event => {
    const { dispatch } = this.props;
    const {
      target: { name, value }
    } = event;
    dispatch(filterLeadsData(name, value));
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchLeadsData());
  }

  renderUTMTags = utmTags => {
    return utmTags ? (
      <React.Fragment>
        {utmTags.utm_source && (
          <div>
            <b>Source:</b> {utmTags.utm_source}
          </div>
        )}
        {utmTags.utm_campaign && (
          <div>
            <b>Campaign:</b> {utmTags.utm_campaign}
          </div>
        )}
        {utmTags.utm_medium && (
          <div>
            <b>Medium:</b> {utmTags.utm_medium}
          </div>
        )}
        {utmTags.utm_content && (
          <div>
            <b>Content:</b> {utmTags.utm_content}
          </div>
        )}
        {utmTags.utm_term && (
          <div>
            <b>Term:</b> {utmTags.utm_term}
          </div>
        )}
      </React.Fragment>
    ) : null;
  };

  render() {
    const { classes, data, loading } = this.props;
    const { rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={classes.root}>
        <h2 className={classes.titleStyle}>Leads</h2>
        {/*<div className={classes.toolbar}>
          <TableFilter
            onChange={this.handleFilterChange}
            filter="name"
            label="Name"
            type="input"
          />
        </div>*/}
        <Table className={classes.table} padding="none">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.headStyle} padding="checkbox">
                Date created
              </TableCell>
              <TableCell className={classes.headStyle} padding="none">
                FullName
              </TableCell>
              <TableCell className={classes.headStyle} padding="none">
                Business Name
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Email
              </TableCell>
              <TableCell className={classes.headStyle} padding="none">
                Phone
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                CreditScore
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Industry Type
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Loan Purpose
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Monthly Revenue
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                Time in business
              </TableCell>
              <TableCell className={classes.headStyle} padding="checkbox">
                UTMTags
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
			  .sort((a, b) => (b.createdAt < a.createdAt ? -1 : 1))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.cellStyle} padding="checkbox">
					{ console.log(row.createdAt, moment.unix(row.createdAt).format("MMM DD YYYY")) }
                    {row.createdAt
                      ? moment.unix(row.createdAt).format("MMM DD YYYY")
                      : ""}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {row.full_name}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {row.business_name}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.email}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="none">
                    {row.mobile_phone}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.credit_score ? row.credit_score.join() : ""}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.industry_type}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.loan_purpose ? row.loan_purpose.join() : ""}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.monthly_revenue}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.time_in_business}
                  </TableCell>
                  <TableCell className={classes.cellStyle} padding="checkbox">
                    {row.utmTags
                      ? this.renderUTMTags(row.utmTags)
                      : "Not available"}
                  </TableCell>
                </TableRow>
              ))}
            <TableEmptyRows
              emptyRows={emptyRows}
              loading={loading}
              dataLength={data.length}
              colSpan={7}
            />
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={2}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

Leads.propTypes = {
  data: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  const nameFilter = state.getIn(["leads", "ui", "nameFilter"]);
  const loading = state.getIn(["leads", "ui", "loading"]);
  const data = state.getIn(["leads", "entities", "leads"]).toJS();
  let filters = [],
    filteredData,
    findInString = (search, forVal) =>
      search.toLowerCase().includes(forVal.toLowerCase());

  if (nameFilter) {
    filters.push(item => {
      return findInString(item.full_name, nameFilter);
    });
  }

  filteredData = !filters.length
    ? Object.values(data)
    : filters.reduce(
        (results, filter) => results.filter(filter),
        Object.values(data)
      );
  return {
    data: filteredData,
    loading,
    token: state.getIn(["auth", "user", "token"])
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Leads));
