import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TravelIcon from '@material-ui/icons/CardTravel';
import LockIcon from '@material-ui/icons/Https';
import get from "lodash.get";

const styles = {
    card: {
      margin:"10px 0", 
      /*width: "300px",*/
      color: "#7F7F7F", 
      height: '560px'
    },
    title: {
      color: '#535353'
    },

    title2: {
        marginTop: '30px',
        color: '#535353',
        fontSize: '14px',
        fontWeight: 'bold'
      },
    pos: {
      marginBottom: '20px',
      color: '#878787',
      fontSize: '14px',
      lineHeight: '22px'

    },
    spaceTitle: {
        display: 'flex',
        justifyContent: 'initial',
        color: '#535353'
    },

    icon: {
        width: 15,
        height: 15,
    },
    link: {
        color: '#FF8700'
    },
    iconTitle: {
        margin: "11px 15px 0 0",
        cursor: "pointer",
    }

  };


class BusinessInfo extends Component {

    render() {
        const { classes, customerDetails } = this.props;
		const application = get(customerDetails,  "applications.0");
		const business = get(application, "business");
		
		if(!application){
			return null;
		}				

        return (
            <React.Fragment>

                <div className={classes.space2}>
                    <div>                    
                        <Card className={classes.card}>
                            <CardContent className={classes.gridStyle}>

                                <div className={classes.spaceTitle}>
                                    <TravelIcon className={classes.iconTitle}/>
                                    <span>
                                            <p>Business info</p>
                                    </span>
                                </div>

                                <p className={classes.title2}>
                                    { get(business, "legalName") }
                                </p>
                                <p className={classes.pos} >
                                    { get(business, "address") }<br />
                                    { get(business, "city") }, { get(business, "state") }, { get(business, "zipCode") }<br />
                                    { get(business, "phone") }<br />
                                    <span className={classes.link}>{ get(business, "website") }</span>  
                                </p>
                                <p className={classes.pos} >
                                    Business start date <br />
                                    { get(business, "startMonth") }/{ get(business, "startYear") }
                                </p>
                                <p className={classes.pos} >
                                    Home based? <br />
                                    { get(business, "homeBased") ? "Yes" : "No"}
                                </p>
                                <p className={classes.pos} >
                                    Legal entity type <br />
                                    { get(business, "legalEntityType.name") }
                                </p>
                                <p className={classes.pos} >
                                <LockIcon className={classes.icon}/> Business tax id <br />
                                    { get(business, "taxId") }
                                </p>   
                            </CardContent>
                        </Card>
                    </div>
                </div>
                        
        </React.Fragment>
        );
    }
}

BusinessInfo.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(BusinessInfo);