import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      light: "#FFBA00",
      main: "#F28C1D",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#23BCAC",
      contrastText: "#FFFFFF"
    },
    text: {
      primary: "#000000",
      secondary: "#6F6F6F"
    }
  },
  typography: {
    fontFamily: "Campton",
    title: {
      color:'#6F6F6F',
    },
  },
});
