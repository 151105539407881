import api from '../config/api';
import { AxiosResponse } from "axios";

const fetchStatuses = async (): Promise<Array<any>> => {
  const response:AxiosResponse = await api.get("/get-statuslist-loanpp");
  return response.data;
};

export default {
  fetchStatuses
};
