import React, { PureComponent, MouseEvent, ChangeEvent, Props } from "react";
import BaseModal from "../../UI/BaseModal/BaseModal";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Grid from "@material-ui/core/Grid";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { DraftNote } from "../../../types/Note";

interface ComponentProps extends WithStyles<typeof styles> {
  open: boolean;
  onSubmit(note: DraftNote): void;
  toggle(e: MouseEvent<HTMLElement>): void;
}

type ComponentState = {
  draft: DraftNote;
};

class NewApplicationNoteModal extends PureComponent<
  ComponentProps,
  ComponentState
> {
  state: ComponentState = {
    draft: {
      typeNote: 0,
      note: ""
    }
  };
  componentDidUpdate(prevProps: ComponentProps) {
    if (prevProps.open === false && prevProps.open !== this.props.open) {
      this.setState(() => ({
        draft: {
          typeNote: 0,
          note: ""
        }
      }));
    }
  }

  handleTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const typeNote: number = Number(event.target.value);
    this.setState((state: ComponentState) => ({
      draft: {
        ...state.draft,
        typeNote
      }
    }));
  };
  handleChangeNote = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const note: string = event.target.value;
    this.setState((state: ComponentState) => ({
      draft: {
        ...state.draft,
        note
      }
    }));
  };
  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { draft } = this.state;

    onSubmit(draft);
  };
  render() {
    const { open, toggle, classes } = this.props;
    const {
      draft: { note, typeNote }
    } = this.state;
    return (
      <BaseModal open={open} title="Create Note" toggle={toggle}>
        <Grid
          container
          item
          spacing={24}
          alignItems="center"
          className={classes.root}
          md={10}
        >
          <Grid item container>
            <Grid item md={3}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="new-note-type-selector">
                  Note type
                </InputLabel>
                <Select
                  value={typeNote}
                  onChange={this.handleTypeChange}
                  input={<Input name="note-type" id="new-note-type-selector" />}
                  name="note-type"
                >
                  <MenuItem value={0}>Note</MenuItem>
                  <MenuItem value={1}>Request</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={12}>
              <OutlinedInput
                multiline
                rows="4"
                rowsMax="10"
                required
                value={note}
                onChange={this.handleChangeNote}
                labelWidth={0}
                autoFocus
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container justify="center">
            <Grid item xs={10} md={6}>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                aria-label="Create"
                className={classes.submitBtn}
                fullWidth
                disabled={!note.trim()}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </BaseModal>
    );
  }
}

export default withStyles(styles)(NewApplicationNoteModal);
