import * as actions from "actions/customers";
import { fromJS } from "immutable";

const initialState = fromJS({
  ui: {
    loading: false,
    nameFilter: "",
    selectedCustomer: ""
  },
  entities: {
    customers: {}
  }
});

const customers = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CUSTOMERS_REQUEST:
      return state.setIn(["ui", "loading"], true);
    case actions.FETCH_CUSTOMERS_SUCCESS: {
      const { customers } = action.payload;
      return state
        .setIn(["ui", "loading"], false)
        .setIn(["entities", "customers"], fromJS(customers));
    }
    case actions.FILTER_CUSTOMERS_DATA:
      return state.setIn(
        ["ui", `${action.payload.key}Filter`],
        action.payload.value
      );
    default:
      return state;
  }
};

export default customers;
