import api from "../config/api";
import { AxiosResponse } from "axios";

const fetchLoginLogData = async () => {
  try {
    let response: AxiosResponse = await api.get("/login-log");
    return response.data;
  } catch (error) {
    throw console.error(`Error fetching login log: ${error.message}`);
  }
};

export default {
  fetchLoginLogData
};
