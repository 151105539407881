import { createSelector } from "reselect";
import get from "lodash.get";

const selectApplicationsById = (state: any) =>
  state.getIn(["applications", "entities", "applicationsById"]);

const selectedApplicationId = (state: any) =>
  state.getIn(["applications", "ui", "selected"]);

export const loginLogData = (state: any) =>
  state.getIn(["loginLog", "entities", "loginLog"]);

const statusFilter = (state: any) =>
  state.getIn(["applications", "ui", "statusFilter"]);
const bizNameFilter = (state: any) =>
  state.getIn(["applications", "ui", "bizNameFilter"]);

const stateFilter = (state: any) =>
  state.getIn(["applications", "ui", "stateFilter"]);

export const selectedApplication = createSelector(
  selectApplicationsById,
  selectedApplicationId,
  (entities, selectedId) => {
    const selected = entities.get(selectedId);
    return selected;
  }
);

export const selectFilteredLoginLog = createSelector(
  loginLogData,
  (data) =>
	data ?
		Object.values(data.toJS()).filter((element: any) => {
		  return (true);
		})
	: []
);
