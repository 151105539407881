import appService from "../services/app";

export const FETCH_STATUSES_REQUEST = "FETCH_STATUSES_REQUEST";
export const FETCH_STATUSES_SUCCESS = "FETCH_STATUSES_SUCCESS";
export const FETCH_STATUSES_FAILURE = "FETCH_STATUSES_FAILURE";

const fetchStatusesRequest = () => ({
  type: FETCH_STATUSES_REQUEST
});

const fetchStatusesFailure = (error: Array<any>) => ({
  type: FETCH_STATUSES_FAILURE,
  error
});

const fetchStatusesSuccess = (payload: Array<any>) => ({
  type: FETCH_STATUSES_SUCCESS,
  payload
});

export const fetchStatuses = () => async (dispatch: Function): Promise<any> => {
  dispatch(fetchStatusesRequest());
  try {
    const data: Array<any> = await appService.fetchStatuses();
    dispatch(fetchStatusesSuccess(data));
  } catch (error) {
    dispatch(fetchStatusesFailure(error));
  }
};
