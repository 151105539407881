import { normalize } from "normalizr";
import loginLogService from "../services/loginLog";
import { loginLogSchema } from "schemas/schemas";

export const FETCH_LOGIN_LOG_REQUEST = "FETCH_LOGIN_LOG_REQUEST";
export const FETCH_LOGIN_LOG_SUCCESS = "FETCH_LOGIN_LOG_SUCCESS";
// export const FETCH_LEADS_FAILURE = "FETCH_LEADS_FAILURE";

const fetchLoginLogDataRequest = () => ({
  type: FETCH_LOGIN_LOG_REQUEST
});

// const fetchCustomerDataFailure = (payload) => ({
//     type: FETCH_CUSTOMER_DATA_FAILURE,
//     payload
// });

const fetchLoginLogDataSuccess = payload => ({
  type: FETCH_LOGIN_LOG_SUCCESS,
  payload
});

export const fetchLoginLogData = () => dispatch => {
  dispatch(fetchLoginLogDataRequest());
  loginLogService.fetchLoginLogData().then(data => {
    const normalizedData = normalize(data, [loginLogSchema]);
    dispatch(fetchLoginLogDataSuccess(normalizedData.entities));
  });
};

export const FILTER_LOGIN_LOG = "FILTER_LOGIN_LOG";

export const filterLoginLogData = (key, value) => ({
  type: FILTER_LOGIN_LOG,
  payload: {
    key,
    value
  }
});
