import api from "../config/api";
import { AxiosResponse } from "axios";

const fetchLeadsData = async () => {
  try {
    let response: AxiosResponse = await api.get("/report-leads");
    return response.data;
  } catch (error) {
    throw console.error(`Error fetching leads: ${error.message}`);
  }
};

export default {
  fetchLeadsData
};
