import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const renderTextField = ({ input, type, meta: { touched, error, warning }, ...props }) => <TextField {...input} type={type} {...props} error={touched && error ? true : null} helperText={touched ? error : null}/>
const required = value => value || value===true || value===false ? undefined : 'Required';

const styles = {
    card: {
        margin:"20px auto", 
        width: "500px",
        color: '#7F7F7F',
        height: "420px",
        textAlign: "center"
      },
      button: {
        fontSize: '14px',
        color: 'white',
        backgroundColor: '#FF8700',
        textTransform: 'none',
        marginTop: "40px"
      },
    title: {
      color: '#535353',
      textAlign: "left"
    },
    closeIcon: {
        color: '#FF8700',
        cursor: 'pointer'
    },
    space: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    form: {
        margin:'auto',
        width: "310px"
    }
  };


class Password extends Component {

  render() {
      const { classes } = this.props;
      return (
          <React.Fragment>
            <div>
                <Card className={classes.card}>
                    <CardContent className={classes.gridStyle}>
                        <div className="card-body">

                            <div className={classes.space}>
                                <span>
                                  <h3 className={classes.title}>Reset confirm</h3>
                                </span>
                                <span><CloseIcon onClick={this.props.onClick} className={classes.closeIcon} /></span>
                            </div>
                            <div className={classes.form}>
                                <Field
                                    name="fundings"
                                    component={renderTextField}
                                    label="Current password"
                                    placeholder="mypassword"
                                    margin="normal"
                                    fullWidth={true}
                                    validate={[required]}
                                />

                                <Field
                                    name="rate"
                                    component={renderTextField}
                                    label="New password"
                                    placeholder="mynewpassword"
                                    margin="normal"
                                    fullWidth={true}
                                    validate={[required]}
                                />

                                <Field
                                    name="term"
                                    component={renderTextField}
                                    label="Confirm password"
                                    placeholder="mynewpassword"
                                    margin="normal"
                                    fullWidth={true}
                                    validate={[required]}
                                />

                                  <Button type="button" variant="contained" color="primary" onClick={this.props.onCreateOffer} className={classes.button}>Reset</Button>
                            </div>
                        </div> 
                    </CardContent>
                    
                </Card>
            </div>

                      
      </React.Fragment>
      );
  }
}

Password.propTypes = {
  classes: PropTypes.object.isRequired,
};

Password = reduxForm(
  {
      form: 'Password',
      destroyOnUnmount: false
  })(Password);


  export default withStyles(styles)(Password);