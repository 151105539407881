import React, { MouseEvent, FunctionComponent, ReactNode } from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import styles from "./styles";

interface ComponentProps extends WithStyles<typeof styles> {
  open: boolean;
  title: ReactNode;
  children: ReactNode;
  toggle(e: MouseEvent<HTMLElement>): void;
}

const BaseModal: FunctionComponent<ComponentProps> = ({
  open,
  toggle,
  classes,
  title,
  children
}) => (
  <Modal open={open} onClose={toggle} className={classes.modal}>
    <Paper className={classes.content}>
      <IconButton onClick={toggle} className={classes.closeBtn}>
        <Close color="primary" />
      </IconButton>
      <Grid container className={classes.root} spacing={24} alignItems="center" justify="center">
        <Grid item container md={11}>
          <Grid item >
            <Typography variant="title" className={classes.title}>{title}</Typography>
          </Grid>
        </Grid>
        {children}
      </Grid>
    </Paper>
  </Modal>
);

export default withStyles(styles)(BaseModal);
