import Applications from "../views/Applications/Applications";
import Customers from "../views/Customers/Customers";
import ApplicationDetails from "../views/Applications/ApplicationDetails/ApplicationDetails";
import AddOffer from "../views/ApplicationDetails/AddOffer";
import CustomerDetails from "../views/CustomerDetails/CustomerDetails";
import Profile from "../views/Profile/Profile";
import Leads from "../views/Leads/Leads";
import LoginLog from "../views/Login/LoginLog";

const privateRoutes = [
    {
        path: "/admin/application/:id/offer",
        component: AddOffer
    },
    {
        path: "/admin/application/:id",
        component: ApplicationDetails,
        exact: true
    },
    {
        path: "/admin/applications",
        component: Applications,
        exact: true,
    },
    {
        path: "/admin/customer/:id",
        component: CustomerDetails
    },
    {
        path: "/admin/customers",
        component: Customers,
        exact: true
    },
    {
        path: "/admin/profile",
        component: Profile
    },
	{
        path: "/admin/leads",
        component: Leads
    },
	{
        path: "/admin/login-log",
        component: LoginLog
    },
    // {
    //     redirect: true,
    //     exact: true,
    //     path: "/admin",
    //     to: "/admin/applications"
    // }
];

export default privateRoutes;
