export default {
  card: {
    //minWidth: 275,
    color: "#7F7F7F",
    overflowWrap: "break-word",
	margin: "0px"
  },
  button: {
      width: '100%',
      height: 100,
      fontSize: '20px',
      color: 'white',
      backgroundColor: '#FF8700',
      textDecoration: 'none'
    },
    buttonStatus: {
      fontSize: '12px',
      color: 'white',
      backgroundColor: '#FF8700',
      textTransform: 'none'
    },
    buttonCard: {
      width: '80%',
      fontSize: '12px',
      color: '#FF8700',
      borderColor: '#FF8700',
      margin: '5px auto',
      display: 'block'
    },
    viewDoc: {
      //color: '#FF8700',
      marginBottom: '20px',
      fontSize: '14px'
    },
    viewDocSpace: {
        //*marginLeft: '20px',
        //cursor: 'pointer'
    },
	title: {
	  color: '#535353'
	},
	name: {
		color: '#FF8700',
		fontSize: '14px'
	},
	image: {
		display: "block",
		marginTop: '15px',
		borderRadius: '50%',
		maxWidth: "95%",
	},
	title2: {
		margin: '20px 0 5px 0',
		color: '#535353',
		fontSize: '14px'
	  },
	content: {
		color: '#535353',
		fontSize: '12px'
	},
	pos: {
	  marginBottom: '20px',
	  color: '#878787',
	  fontSize: '14px'
	},
	ButtonGroup: {
		marginBottom: '50px'
	},
	posNumber: {
		color: '#878787',
		fontSize: '20px'
	  },
    icon: {
        color: '#23BCAC',
        marginTop: '10px',
        width: 40,
        height: 40,
    },
    select: {
      color: '#878787',
      padding: '12px',
      width: 'auto',
      fontSize:'16px',
      textAlign: 'center',
      borderRadius: '3px',
      border: '0',
      outline: '0',
      transition: '0.3s ease all',
      marginBottom: '42px',
      display: 'block'
    },
    space: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    addIcon: {
        marginTop: '15px',
        color: '#FF8700',
        cursor: 'pointer'
    },
    noteStatus: {
      color: '#23BCAC',
      fontSize: '14px',
      marginTop: '-10px',
      paddingLeft: '5px',
      borderLeft: '1px solid #23BCAC'
	},
	iconDoc: {
		verticalAlign: "middle",
		cursor: "pointer"
	},
	docName: {
		verticalAlign: "middle",
		cursor: "pointer"
	},
	spaceDoc: {
      display: 'flex',
      justifyContent: 'initial',      
	},

};