import { createStyles, Theme } from "@material-ui/core/styles";

export default ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      marginTop: spacing.unit * 3,
      overflowX: "auto",
      padding: "25px 50px 0 50px"
    },
    toolbar: {
      display: "flex",
      marginBottom: "20px"
    },
    table: {
      width: "100%"
    },
    head: {
      background: "#F1F3F7",
      paddingRight: "2px"
    },
    cellStyle: {
      color: "#7F7F7F",
      fontSize: "0.9rem"
    },
    headStyle: {
      color: "#777F96",
	  fontWeight: "bold",
	  fontSize: "1rem"
    },
    titleStyle: {
      color: "#777F96"
    },
    cellCenter: {
      textAlign: "center",
      color: "#7F7F7F",
      fontSize: "12px"
    },
    cellWidth: {
      width: "50px",
      color: "#777F96",
      fontSize: "0.85rem",
	  fontWeight: "bold"
    },
    selectable: {
      cursor: "pointer",
      "&:hover": {
        background: "#F1F3F7"
      }
    },
    link: {
      color: palette.primary.main,
      textDecoration: "underline",
      userSelect: "none"
    }
  });

