import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import store, { history } from './store'
import indexRoutes from 'routes/index.ts';
import theme from './theme.ts';
import { Switch } from 'react-router-dom';
import { switchRoutes } from 'helpers/routes';

import 'assets/scss/style.css';

import { MuiThemeProvider } from '@material-ui/core/styles';

const target = document.querySelector('#root');

render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
                <Switch>
                    { switchRoutes(indexRoutes) }
                </Switch>
            </ConnectedRouter>
        </MuiThemeProvider>
    </Provider>,
    target
);
